import {BaseComponent} from '../shared/components/base.component';
import {Component, OnInit} from '@angular/core';
import {DocumentsService} from '../shared/service/documents.service';
import {Localization} from '../shared/enums/localization';
import {Document} from '../shared/model/document';
import { ApplicationConfig } from 'src/app/config/application.config';
import {ApplicationStyler} from '../shared/service/application-styler.service';
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-user-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  providers: [DocumentsService]
})
export class DocumentsComponent extends BaseComponent implements OnInit {

  public readonly Localization = Localization;
  public documents: Document[];
  public documentsAreLoading: boolean = true;
  public isListEmpty: boolean = false;
  constructor(private documentsService: DocumentsService, public applicationConfig: ApplicationConfig, private styler: ApplicationStyler) {
    super();
    this.styler.pageName.next(this.Localization.Documents);


  }

  ngOnInit() {
    this.loadDocuments();
  }

  public download(document: Document){
    window.open(document.url, '_blank');
  }

  private loadDocuments(){
    this.documentsAreLoading = true;
    this.documentsService.getDocuments()
        .pipe(finalize(() => this.documentsAreLoading = false))
        .subscribe((result) => {
      result.length == 0 ? this.isListEmpty = true : this.isListEmpty = false;
      this.documents = result;
    });
  }
}
