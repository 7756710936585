import {Observable, of} from 'rxjs';
import {delay} from 'rxjs/internal/operators';

export class PhotoUploadService {
  constructor() {}

  public uploadPhoto(file: File): Observable<string> {
    return of('https://images.fineartamerica.com/images-medium-large/abandoned-room-matt-hanson.jpg').pipe(delay(1000));
  }
}
