<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{ Localization.UpdateAvailable | translate }}</h4>
    </div>
    <div class="modal-body">
        {{ Localization.UpdateAvailableMessage | translate }}
    </div>
    <div class="modal-footer">
        <chapps-secondary-button [label]="Localization.OK | translate" [small]="true" (clicked)="closeAndUpdate()">
            </chapps-secondary-button>
    </div>
</div>