import {BaseComponent} from '../../../base.component';
import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {IssueNotificationMessage} from '../../../../model/issue-notification-message';

@Component({
  selector: 'app-my-message',
  templateUrl: './my-message.component.html',
  styleUrls: ['./my-message.component.scss'],
})
export class MyMessageComponent extends BaseComponent implements OnInit {

  @Input() message: IssueNotificationMessage;
  @Output() photoClick: EventEmitter<string> = new EventEmitter<string>();
 
  constructor() {
    super();
  }  
  ngOnInit(): void {

  }
}
