import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiRoutes} from '../config/api-routes';
import {LocalizationService} from '../modules/shared/service/localization.service';

@Injectable({
  providedIn: 'root'
})
export class ManifestService {

  constructor(
    private localizationService: LocalizationService,
    private httpClient: HttpClient,
    private routes: ApiRoutes) { }

  public setManifest() {
    //@TODO Refactor an alternate way to retrieve the Business ID
    // this.businessStyleService.businessStyleSettings.subscribe({
    //   next: (settings) => {
    //     const language = this.localizationService.getStartingLanguage();
    //     let url = this.routes.getRouteWithInjectedParams(this.routes.MANIFEST, [language]);
    //     if (settings.businessId > 0) {
    //       url = this.routes.getRouteWithInjectedParams(this.routes.MANIFEST_FOR_BUSINESS, [language, settings.businessId.toString()]);
    //     }
    //     this.httpClient.get(url).subscribe(manifest => {
    //       const manifestFile = new Blob([JSON.stringify(manifest)], { type: "application/json" });
    //       let url = window.URL.createObjectURL(manifestFile);
    //       document.querySelector('#manifest-placeholder').setAttribute('href', url);
    //     });
    //   }
    // });
  }
}
