import { Injectable } from "@angular/core";
@Injectable()
export class ApplicationConfig {

  // Default BusinessStyleSettings
  // TODO 'unmake' static - needs changes to ChappsCommonAngular constructor
  public static readonly DEFAULT_LOGO_URL: string = 'assets/images/chappslogo.png';
  public static readonly DEFAULT_BACKGROUND_URL: string = 'assets/background.jpg';

  // titles
  public static readonly DEFAULT_TITLE_TEXT_COLOR: string = '#C0BED4';
  public static readonly DEFAULT_PRIMARY_COLOR: string = '#3939BF08';
  public static readonly DEFAULT_SECONDARY_COLOR: string = '#001659';
  public static readonly DEFAULT_TERTIARY_COLOR: string = '#6A86D9';

  // text
  public static readonly DEFAULT_TEXT_COLOR: string = '#FFFFFF';
  public static readonly DEFAULT_PRIMARY_TEXT_COLOR: string = '#001659';
  public static readonly DEFAULT_SECONDARY_TEXT_COLOR: string = '#A6A6A6';
  public static readonly DEFAULT_TERTIARY_TEXT_COLOR: string = '#808080';

  public static readonly DEFAULT_BUTTON_COLOR: string = "#6A86D9"
  public static readonly DEFAULT_SVG_COLOR: string = '#6A86D9';
  public static readonly DEFAULT_SECONDARY_SVG_COLOR: string = "#001659";
  public static readonly DEFAULT_SVG_COLOR_DONE: string = "#C0BED4";

  public static readonly DEFAULT_FONT: string = 'Roboto';

  public readonly DATE_FORMAT: string = 'dd/MM/yyyy';
  public readonly DATE_TIME_FORMAT: string = 'dd/MM/yyyy HH:mm';
  public readonly DAY_TIME_FORMAT: string = 'EEEE HH:mm';
  public readonly DATE_TIME_CALENDAR_FORMAT: string = 'dd MMM';

  public readonly PDF_ICON_URL: string = 'assets/images/file-extensions/pdf.png';
  public readonly MIN_PASSWORD_LENGTH: number = 6;
  public readonly BUILDING_MANAGER_BUILDING_THUMBNAIL: string = 'assets/images/buildman-building-thumbnail.png';
  public readonly DORM_BUILDING_THUMBNAIL: string = 'assets/images/buildman-building-thumbnail.png';
  public readonly DEFAULT_BUILDING_THUMBNAIL: string = 'assets/images/buildman-building-thumbnail.png';
  public readonly MAX_RESOLUTION_TO_SHOW_BACK_BUTTON: number =  1199;
  public readonly BACK_BUTTON_REGEXP_PAGES: string[] = [
    '^\\/notifications\\/\\d*',
    '^\\/announcements\\/\\d*',
  ];
}

