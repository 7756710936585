import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorageService } from "../modules/shared/service/local-storage.service";

@Pipe({
    name: 'customDate',
    pure: false
})

export class CustomDatePipe implements PipeTransform {
    constructor(private localStorageService: LocalStorageService) {}

    transform(value: any, pattern: string = 'mediumDate'): any {
      const datePipe: DatePipe = new DatePipe(this.localStorageService.getCulture());
      return datePipe.transform(value, pattern);
    }
}