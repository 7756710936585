import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/internal/operators";
import {ApiRoutes} from "../../../config/api-routes";
import {StructureSummary} from "../model/structureSummary.model";

@Injectable()
export class StructureService {

  constructor(private httpClient: HttpClient, private apiRoutes: ApiRoutes) {
  }

  public getStructures(parentStructureId: string): Observable<any> {
    return this.httpClient.get(
      this.apiRoutes.getRouteWithInjectedParams(this.apiRoutes.STRUCTURES, [parentStructureId]))
      .pipe(
        catchError(StructureService.handleError)
      );
  }

  public getFilteredStructures(...filters: [string|number, LocationPart, LocationAccess]): Observable<StructureSummary[]> {
    return this.httpClient.get<StructureSummary[]>(
      this.apiRoutes.getRouteWithInjectedParams(this.apiRoutes.FILTERED_STRUCTURES, filters)
    ).pipe(
      catchError(StructureService.handleError)
    );
  }

  private static handleError(error): Observable<null> {
    console.log(error);
    return of(null);
  }

}

export enum LocationPart {
  interior = 'interior',
  exterior = 'exterior',
}

export enum LocationAccess {
  private = 'private',
  public = 'public',
}
