import { NgModule } from "@angular/core";
import { CustomAddressPipe } from "./address.pipe";
import { CustomDatePipe } from "./date.pipe";

@NgModule({
    declarations: [
      CustomAddressPipe,
      CustomDatePipe
    ],
    imports: [],
    exports: [
      CustomAddressPipe,
      CustomDatePipe
    ]
  })
  
  
  export class PipesModule {
  }