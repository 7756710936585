<app-card-spinner [show]="issuesAreLoading" [isFullScreen]="true"></app-card-spinner>
<ng-container *ngIf="!issuesAreLoading">
  <ng-container *ngIf="!isListEmpty; else emptyList">
    <mat-accordion [multi]="true">
      <ng-container *ngFor="let issue of issues; index as i">
        <mat-expansion-panel hideToggle class="expansion-panel" (opened)="onPanelOpen(i)" (closed)="onPanelClosed(i)">
          <mat-expansion-panel-header style="background: none;">
            <mat-panel-title>
                <div class="panel-header">
                    <app-icon-thumbnail [icon]="issue.statusIcon" [title]="issue.label" class="mx-3" [color]="issue.colorScheme" [showBadge]="issue.hasUnreadMessage">
                    </app-icon-thumbnail>
                    <div >{{issue.problem}}</div>
                </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="panel-body" >
            <div class="panel-body-item">
              <svg-icon appSVGColor="issue.colorScheme" [src]="'assets/icons/Location.svg'" [svgStyle]="{'height.px':24, 'padding-bottom.px': 2}" class="svg-secondary-text"></svg-icon>
              <div>{{issue.problemLocation}}</div>
            </div>
            <div class="panel-body-item">
              <svg-icon appSVGColor="issue.colorScheme" [src]="'assets/icons/DateRange.svg'" [svgStyle]="{'height.px':24, 'padding-bottom.px': 2}" class="svg-secondary-text"></svg-icon>
              <div>{{issue.observedOn | date: applicationConfig.DATE_TIME_FORMAT}}</div>
            </div>
            <ng-container *ngIf="issue.description?.length > 0">
              <div  class="panel-body-item">
                <div>{{issue.description}}</div>
              </div>
            </ng-container>
            <ng-container *ngIf="issue.photos?.length > 0">
              <div class="panel-body-item">
                <chapps-image *ngFor="let photo of issue.photos"
                              [id]="photo.id"
                              [url]="photo.url"
                              [deletable]="false"
                              (clicked)="onPhotoClick(issue.photos, photo.url)">
                </chapps-image>
                <app-card-spinner [show]="isImagesLoading"></app-card-spinner>
            </div>
            </ng-container>
            <div class="d-flex flex-column" *ngIf="issue.isViewOpened">
              <div class="d-flex flex-row">
                <svg-icon [src]="'assets/icons/Message.svg'" [svgStyle]="{'height.px':24, 'padding-bottom.px': 2}" class="svg-secondary-text"></svg-icon>
                <div>{{ Localization.Communications | translate }}</div>
              </div>
              <app-notification-messages [issueNotificationId]="issue.id" style="width: 100%;"></app-notification-messages>
            </div>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </ng-container>
</ng-container>
<ng-template #emptyList>
  <app-empty-list [icon]="'ProblemStatus'" [title]="Localization.NoProblems | translate" [emptyText]="Localization.NoProblemsDescription | translate"></app-empty-list>
</ng-template>
