import {HttpHeaders, HttpRequest} from '@angular/common/http';

export class HttpHeadersService {

  public hasNoModalHeader(req: HttpRequest<any>): boolean {
    return req.headers.has('no-error-modal');
  }

  public hasNoLogHeader(req: HttpRequest<any>): boolean {
    return req.headers.has('no-log');
  }

  public addNoLogHeader(headers: HttpHeaders): HttpHeaders {
    return headers.set('no-log', 'yes');
  }

}
