<app-card-spinner [show]="announcementsLoading" [isFullScreen]="true"></app-card-spinner>
<ng-container *ngIf="!announcementsLoading">
    <ng-container *ngIf="!isListEmpty; else emptyList">
        <div class="announcements-page">
            <app-page-header
                [title]="Localization.Announcements | translate"
                [description]="Localization.AnnouncementsInfo | translate"
                [icon]="'fas fa-bullhorn fa-4x'">
            </app-page-header>
            <mat-accordion>
                <ng-container *ngFor="let announcement of announcements; index as i">
                    <mat-expansion-panel hideToggle class="expansion-panel">
                        <mat-expansion-panel-header style="background: none;">
                            <mat-panel-title>
                                <div class="panel-header">
                                    <app-calendar-symbol [calendarDate]="announcement.from" [calendarColor]="announcement.mediaColor" [showBadge]="!announcement.isRead"></app-calendar-symbol>
                                    <div >{{announcement.title}}</div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                    <div class="panel-body" >
                        <div class="panel-body-item" *ngIf="announcement.description">
                            <div>{{announcement.description}}</div>
                        </div>
                        <div class="panel-body-item">
                            <svg-icon [appSVGColor]="announcement.mediaColor" [name]="'DateRange'" [svgStyle]="{'height.px':24, 'padding-bottom.px': 2}" class="svg-secondary-text"></svg-icon>
                            <div>{{announcement.from | date: applicationConfig.DATE_FORMAT}} <span *ngIf="announcement.to">-</span> {{announcement.to | date: applicationConfig.DATE_FORMAT}}</div>
                        </div>
                        <div class="panel-body-item" *ngIf="announcement.multimedia">
                            <chapps-image *ngFor="let photo of announcement.multimedia"
                                          [id]="photo.id"
                                          [url]="photo.url"
                                          [deletable]="false"
                                          (clicked)="onPhotoClick(issue.photos, photo.url)">
                            </chapps-image>
                            <app-card-spinner [show]="isImagesLoading"></app-card-spinner>
                        </div>
                    </div>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </div>
    </ng-container>
</ng-container>

<ng-template #emptyList>
    <app-empty-list [icon]="'Announcements'" [title]="Localization.NoAnnouncements | translate" [emptyText]="Localization.NoAnnouncementsDescription | translate"></app-empty-list>
</ng-template>
