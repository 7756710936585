import {Component, Input} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base.component';
import {IssueNotificationMessage} from '../../../shared/model/issue-notification-message';
import { PhotoViewerService } from 'src/app/components/photo-viewer/photo-viewer.service';
import { ApplicationConfig } from 'src/app/config/application.config';

@Component({
  selector: 'app-issue-notification-chat',
  templateUrl: './issue-notification-chat.component.html',
  styleUrls: ['./issue-notification-chat.component.scss'],
})
export class IssueNotificationChatComponent extends BaseComponent {

  @Input() messages: IssueNotificationMessage[];
  @Input() replyInProgress: boolean;

  constructor(public applicationConfig: ApplicationConfig, private photoViewerService: PhotoViewerService) {
    super();
  }

  public isThisLoggedUserMessage(message: IssueNotificationMessage): boolean {
    return !message.loginId;
  }

  public onPhotoClick(url: string) {
    const imageMessages = this.messages.filter(x => x.photoUrl);
    this.photoViewerService.show(
      imageMessages.map(message => message.photoUrl),
      imageMessages.findIndex(message => message.photoUrl === url)
    );
  }

  public showMessageDate(sentOn: string, index: number){
    if(index == 0){
      return true;
    }else{
      let dateCurrentMessage = new Date(sentOn);
      let datePreviousMessage = new Date(this.messages[index - 1].sentOn);
      
      if(this.sameDayAndHour(dateCurrentMessage, datePreviousMessage)){
        return false;
      }else{
        return true;
      }
    }
  }

  private sameDayAndHour(d1: Date, d2: Date): boolean {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate() && d1.getHours() == d2.getHours();
  }
}



