import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthorizationService} from '../modules/shared/service/authorization.service';

@Injectable()
export class LoggedInGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthorizationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isUserLoggedIn()) {

      const businessUrlKey = route.params['business-url-key'];
      if (businessUrlKey) {
        this.router.navigateByUrl('login?business-url-key=' + businessUrlKey);
      } else {
        this.router.navigateByUrl('login');
      }
    } else {
      return true;
    }
  }
}

