<ng-container *ngIf="(currentLogin$ | async) as currentLogin">
  <div class="account-page">
    <div class="account-header" [ngClass]="{'account-header-desktop' : (isDesktop$ | async)}">
      <div class="logo">
        <ng-container *ngIf="!currentLogin.thumbnailUrl; else imageContainer">
          <a class="round profile-photo"><i class="fas fa-user fa-4x"></i></a>
        </ng-container>
        <ng-template #imageContainer>
          <img class="round profile-photo" [src]="currentLogin.thumbnailUrl">
        </ng-template>
        <!--<input type="file" accept="image/*" #image (change)="fileChangeEvent($event)" style="display: none" multiple capture="environment">
        <a (click)="uploadPhoto()" class="round upload-photo"><i class="fas fa-camera"></i></a>-->
      </div>
      <div class="user-info">
        <div>{{currentLogin.firstName}} {{currentLogin.lastName}}</div>
        <div *ngIf="currentBuilding">{{ currentBuilding.address | customAddress: "street"}}</div>
      </div>
    </div>

    <div class="account-section">
      <div class="account-section-title">{{Localization.ContactInformation | translate}}</div>
      <div class="account-section-info">
        <p>{{currentLogin.telephone}}</p>
        <p>{{currentLogin.username}}</p>
      </div>

    </div>

    <div class="account-section">
      <div class="account-section-title">{{Localization.Password | translate}}</div>
      <div class="account-section-info">
        <a (click)="resetPassword(currentLogin)"><span>{{Localization.ResetPassword | translate}}</span></a>
      </div>
    </div>

    <div class="account-section"
         (click)="logout()">
      <a>
        <div class="account-section-title">{{Localization.Logout | translate}}</div>
      </a>
    </div>
  </div>
</ng-container>

<!--<chapps-image-cropper-modal (fileCropped)="fileCropped($event)"></chapps-image-cropper-modal>-->
