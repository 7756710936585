import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../shared/components/base.component';
import { IssueNotificationService } from '../shared/service/issue-notification.service';
import { ApplicationConfig } from '../../config/application.config';
import { Localization } from '../shared/enums/localization';
import { IssueNotificationStatus } from '../shared/enums/enums';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { IssueNotificationView } from '../shared/model/issue-notification';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Photo } from '../shared/model/photo';
import { PhotoViewerService } from 'src/app/components/photo-viewer/photo-viewer.service';
import { LocalStorageService } from '../shared/service/local-storage.service';
import {ApplicationStyler} from '../shared/service/application-styler.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent extends BaseComponent implements OnInit {

  public readonly Localization = Localization;


  issues: IssueNotificationView[];
  snapshot: ActivatedRouteSnapshot;
  params: any;
  issueClicked: boolean = false;
  issuesAreLoading: boolean = true;
  isListEmpty: boolean = false;

  public isImagesLoading: boolean = false;

  constructor(private issueNotificationService: IssueNotificationService, public appConfig: ApplicationConfig,
    private translate: TranslateService, route: ActivatedRoute, public applicationConfig: ApplicationConfig,
    private photoViewerService: PhotoViewerService, private styler: ApplicationStyler) {
    super();
    this.styler.pageName.next(this.Localization.ProblemStatus);
    this.snapshot = route.snapshot;
  }

  ngOnInit() {
    this.loadIssues(this.snapshot.queryParams as HttpParams);
  }

  public get issueNotificationStatus(): typeof IssueNotificationStatus {
    return IssueNotificationStatus;
  }

  private loadIssues(httpParams: HttpParams){
    this.issuesAreLoading = true;
    this.issueNotificationService.getNotifications(httpParams).subscribe((result: IssueNotificationView[]) => {
      result.forEach((element: IssueNotificationView) => {
        if(element.status == IssueNotificationStatus.HANDLED){
          element.statusIcon = "ClosedStatus";
          element.colorScheme = "done";
        }else {
          element.statusIcon = "OpenStatus";
          element.colorScheme = "primary"
        }

        element.problem = element.title.substring(this.nthIndex(element.title, '/', 3) + 1);
        element.problemLocation = element.title.substring(element.title.indexOf('/') + 1, this.nthIndex(element.title, '/', 3));
      });
      result.length == 0 ? this.isListEmpty = true : this.isListEmpty = false;
      this.issues = result;
      this.issuesAreLoading = false
    })
  }

  private nthIndex(str: string, pat: string, n: number){
    var L= str.length, i= -1;
    while(n-- && i++<L){
        i= str.indexOf(pat, i);
        if (i < 0) break;
    }
    return i;
}

  onPanelOpen(index: number){
    const issue = this.issues[index];
    issue.isViewOpened = true;
    issue.hasUnreadMessage = false;
    this.getMedia(issue);
  }

  onPanelClosed(index: number){
    const issue = this.issues[index];
    issue.isViewOpened = false;
  }

  private getMedia(issue: IssueNotificationView) {
    this.isImagesLoading = true;
    this.issueNotificationService.getIssueNotificationMedia(issue.id).subscribe(
      response => issue.photos = response,
      () => this.isImagesLoading = false,
      () => this.isImagesLoading = false
    );
  }

  public onPhotoClick(photos: Photo[], url: string) {
    this.photoViewerService.show(
      photos.map(photo => photo.url),
      photos.findIndex(photo => photo.url === url)
    );
  }
}
