import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-icon-thumbnail',
  templateUrl: './icon-thumbnail.component.html',
  styleUrls: ['./icon-thumbnail.component.scss'],
})
export class SmallIconThumbnailComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() color: string;
  @Input() showBadge: boolean;
  @Input() unReadMessages: number;
  @Input() height: number = 35;
}
