<div mdbModal #modal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true}">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <p class="mt-4" [translate]="Localization.AnnouncementNotFound"></p>
        </div>
        <div class="modal-footer text-right">
          <chapps-secondary-button [label]="Localization.Announcements | translate"
                                   [small]="true"
                                   (clicked)="goToAnnouncements()">
          </chapps-secondary-button>
        </div>
      </div>
  </div>
</div>
