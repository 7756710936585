import {Injectable} from '@angular/core';
import {
  HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse,
  HttpSentEvent, HttpUserEvent
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/internal/operators';
import {RefreshTokenService} from '../modules/shared/service/refresh-token.service';
import {AuthorizationService} from '../modules/shared/service/authorization.service';
import {Router} from '@angular/router';
import {LoggerService} from '../modules/shared/service/logger.service';
import {LocalStorageService} from '../modules/shared/service/local-storage.service';
import {HttpHeadersService} from '../modules/shared/service/http-headers.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private logger: LoggerService, private router: Router, private refreshTokenService: RefreshTokenService,
              private authorizationService: AuthorizationService, private localStorageService: LocalStorageService,
              private headersService: HttpHeadersService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (this.localStorageService.doesTokenExist()) {
      return next.handle(this.getRequestWithAuthorization(req)).pipe(
        catchError((error, caught) => {
          if (error instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>error).status) {
              case 401:
              case 403:
                return this.refreshTokenService.refreshToken().pipe(
                  switchMap(response => {
                    if (response && response.token) {
                      this.authorizationService.initSession(response);
                      return next.handle(this.getRequestWithAuthorization(req));
                    } else {
                      this.authorizationService.clearSession();
                      this.router.navigateByUrl('login');
                      this.logError(error, req);
                      return throwError('Could not refresh token');
                    }
                  })
                );
                break;
              default:
                this.logError(error, req);
                return throwError(error);
            }
          } else {
            return next.handle(req);
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }

  private logError(error, req) {
    const showModal = !this.headersService.hasNoModalHeader(req);
    const logError = !this.headersService.hasNoLogHeader(req);
    if (logError) {
      this.logger.httpRequestError(error.status, req.method, req.urlWithParams, JSON.stringify(req.body), showModal);
    }
  }

  private getRequestWithAuthorization(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({headers: request.headers.set('Authorization', 'Bearer ' + this.localStorageService.getToken())});
  }
}
