<app-card-spinner [show]="documentsAreLoading" [isFullScreen]="true"></app-card-spinner>
<ng-container *ngIf="!documentsAreLoading">
  <ng-container *ngIf="!isListEmpty; else emptyList">
    <div class="documents-page">
      <app-page-header
              [title]="Localization.Documents | translate"
              [description]="Localization.DocumentsInfo | translate"
              [icon]="'fas fa-file-alt fa-4x'">
          </app-page-header>
      <mat-accordion>
        <ng-container *ngFor="let document of documents">
            <mat-expansion-panel hideToggle class="expansion-panel">
                <mat-expansion-panel-header style="background: none;">
                    <mat-panel-title>
                        <div class="panel-header">
                            <app-calendar-symbol calendarColor="primary" [calendarDate]="document.createdOn"></app-calendar-symbol>
                            <div >{{document.label}}</div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

            <div class="panel-body" >
                <div class="panel-body-item">
                  <svg-icon appSVGColor [src]="'assets/icons/Person.svg'" [svgStyle]="{'height.px':24, 'padding-bottom.px': 2}" class="svg-secondary-text"></svg-icon>
                  <div>{{Localization.AddedBy | translate}} {{ document.author}}</div>
                </div>
                <div class="panel-body-item">
                  <svg-icon appSVGColor [src]="'assets/icons/DateRange.svg'" [svgStyle]="{'height.px':24, 'padding-bottom.px': 2}" class="svg-secondary-text"></svg-icon>
                  <div>{{Localization.AddedOn | translate}} {{document.createdOn | date: applicationConfig.DATE_TIME_FORMAT}}</div>
                </div>
                <div class="panel-body-item">
                  <svg-icon appSVGColor [src]="'assets/icons/File.svg'" [svgStyle]="{'height.px':24, 'padding-bottom.px': 2}" class="svg-secondary-text"></svg-icon>
                  <div>{{document.filename}}</div>
                </div>
                <button buttonColor type="button" class="submit" mdbBtn  brightness="normal" mdbWavesEffect (click)="download(document)" [translate]="Localization.Download | translate"></button>
            </div>
            </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>


  </ng-container>
</ng-container>
<ng-template #emptyList>
  <app-empty-list [icon]="'Documents'" [title]="Localization.NoDocuments | translate" [emptyText]="Localization.NoDocumentsDescription | translate"></app-empty-list>
</ng-template>
