<div class="container-fluid d-flex flex-column justify-content-center align-items-center">
  <img *ngIf="logoUrl" src="{{logoUrl}}" alt="Company logo"/>
  <mdb-card *ngIf="!isLoading" id="login-card" class="m-3">
    <mdb-card-body [hidden]="isConfirmationCodeCorrect">
        <div class="alert alert-danger mb-0" role="alert">{{confirmationCodeErrorMessage}}</div>
    </mdb-card-body>
    <mdb-card-body [hidden]="!isConfirmationCodeCorrect" class="pb-2">
      <span *ngIf="!isPasswordCreationDone"  class="font-weight-500" [translate]="Localization.SetPasswordWelcomeMessage" [translateParams]="{firstName: firstName}"></span>
      <span *ngIf="isPasswordCreationDone"  class="font-weight-500" [translate]="Localization.Success"></span>
      <p *ngIf="!isPasswordCreationDone" class="text-left mt-2 mb-4" [translate]="Localization.SetPasswordMessage"></p>
      <p *ngIf="isPasswordCreationDone" class="text-left mt-2 mb-4" [translate]="Localization.PasswordSetSuccess"></p>
      <ng-container *ngIf="!isPasswordCreationDone">
        <form #form="ngForm">
          <input type="password" id="passwordInput" name="passwordInput" class="form-control mb-2" (focus)="onInputFocus()"
                 [(ngModel)]="password"
                 [placeholder]="Localization.Password | translate">
          <input type="password" id="confirmPasswordInput" name="confirmPasswordInput" class="form-control mb-2" (focus)="onInputFocus()"
                 [(ngModel)]="confirmPassword"
                 [placeholder]="Localization.ConfirmPassword | translate">
        </form>
        <div *ngIf="!doesPasswordHaveTargetedLenght() && form.touched" class="alert alert-warning mb-0" role="alert"
             [translate]="Localization.BadPasswordLength" [translateParams]="{value: applicationConfig.MIN_PASSWORD_LENGTH}"></div>
        <div *ngIf="!areBothPasswordEqual() && doesPasswordHaveTargetedLenght()" class="alert alert-warning mb-0" role="alert"
             [translate]="Localization.PasswordsDoNotMatch"></div>
        <div *ngIf="isPasswordCreationError" class="alert alert-danger mb-0" role="alert"
             [translate]="Localization.PasswordSetError"></div>
      </ng-container>
    </mdb-card-body>
    <mdb-card-footer [hidden]="!isConfirmationCodeCorrect" class="text-center">
      <button *ngIf="!isProccessing && !isPasswordCreationDone" type="button" class="mb-2"
              mdbBtn
                            brightness="normal"
              [block]="true"
              (click)="onSubmit()"
              mdbWavesEffect
              [disabled]="!areBothPasswordEqual() || !doesPasswordHaveTargetedLenght()"
              [translate]="Localization.Submit">
      </button>
      <button *ngIf="isPasswordCreationDone" type="button"
              mdbBtn
              color="light-green"
              [block]="true"
              (click)="goToLoginPage()"
              mdbWavesEffect
              [translate]="Localization.GoToLoginPage">
      </button>
      <mdb-spinner *ngIf="isProccessing"></mdb-spinner>
    </mdb-card-footer>
  </mdb-card>
</div>
<div class="page-spinner" *ngIf="isLoading">
  <mdb-spinner spinnerType="big"></mdb-spinner>
</div>

