import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../shared/components/base.component';
import {IssueNotificationTemplate} from '../shared/model/issue-notification-template';
import {IssueNotificationService} from '../shared/service/issue-notification.service';
import {DashboardService} from './dashboard.service';
import {DashboardTile} from './model/dashboard-tile';
import {BuildingService} from '../shared/service/building-service';
import {BehaviorSubject, noop, Subscription} from 'rxjs';
import {Building} from '../shared/model/building';
import {Router} from '@angular/router';
import {LocalStorageService} from '../shared/service/local-storage.service';
import {AnnouncementsService} from '../shared/service/announcements.service';
import {LayoutService} from 'src/app/services/layout.service';
import {ApplicationStyler} from '../shared/service/application-styler.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DashboardService]
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {

  private descriptions = [this.Localization.DwellingDescription, this.Localization.BuildingDescription, this.Localization.ExternalDescription]
  public areBuildingsLoading: boolean;
  public areTemplatesLoading: boolean;
  public dynamicTiles: IssueNotificationTemplate[];
  public fixedTiles: DashboardTile[];
  public buildings: Building[];
  public currentBuilding: Building;
  private subscriptions: Subscription[] = [];
  isDesktop$: BehaviorSubject<boolean>;

  public get tenantOfMultipleBuildings() {
    return this.buildings?.length > 1;
  }

  constructor(private issueNotificationService: IssueNotificationService, private dashboardService: DashboardService,
              private buildingService: BuildingService, private router: Router, private localStorageService: LocalStorageService,
              private announcementsService: AnnouncementsService,
              private layoutService: LayoutService,
              private styler: ApplicationStyler) {
    super();
    this.styler.pageName.next(this.Localization.ApplicationName);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {
    this.isDesktop$ = this.layoutService.isDesktop$;
    this.areBuildingsLoading = false;
    this.areTemplatesLoading = false;
    this.dynamicTiles = [];
    this.fixedTiles = [];
    this.buildings = [];
    this.loadData();
    this.getFixedTiles();
  }

  public getUserFirstName(): string {
    return this.localStorageService.getFirstname();
  }

  public onNotificationTileSelect(template: IssueNotificationTemplate) {
    this.router.navigateByUrl(`new-notification?buildingId=${this.currentBuilding.id}&type=${template.type}&templateId=${template.id}&selectedTemplatesPath=${template.id}`);
  }

  public onFixedTileSelected(tile: DashboardTile){
    if (!tile.disabled) {
      this.router.navigateByUrl(tile.page);
    }
  }

  public isDataLoading(): boolean {
    return this.areBuildingsLoading || this.areTemplatesLoading;
  }

  public doesUserHaveAnyBuildings(): boolean {
    return this.buildings.length > 0;
  }

  public doesUserHaveOneBuilding(): boolean {
    return true;
    //return this.buildings.length === 1;
  }

  private loadData() {
    this.areBuildingsLoading = true;
    this.subscriptions.push(this.buildingService.getBuildings().subscribe(
      buildings => {
        this.buildings = buildings;
        this.currentBuilding = buildings[0];
        this.loadTemplates(this.currentBuilding.id, this.currentBuilding.application);
      },
      noop,
      () => this.areBuildingsLoading = false
    ));

  }



  private loadTemplates(buildingId: number, application: string) {
    this.areTemplatesLoading = true;
    this.subscriptions.push(this.issueNotificationService.getTemplates(buildingId, application, null).subscribe(
      (response) => {
        this.dynamicTiles = response;
        this.dynamicTiles.forEach((tile, index) => {
          tile.description = this.descriptions[index];
        })
      },
      noop,
      () => this.areTemplatesLoading = false
    ));

  }

  private getFixedTiles() {

    this.subscriptions.push(this.issueNotificationService.getNotifications().subscribe((result) => {
      let filteredList = result.filter(x => x.hasUnreadMessage == true);
      if(filteredList.length > 0){
        this.dashboardService.updateTileUnreadMessages(this.Localization.IssueStatus, filteredList.length);
      }
      this.fixedTiles = this.dashboardService.getTiles();
    }));

    this.subscriptions.push(this.announcementsService.hasResidentAnyUnreadAnnouncements().subscribe(
      response => {
        if(response.announcementsIds.length > 0){
          this.dashboardService.updateTileUnreadMessages(this.Localization.Announcements, response.announcementsIds.length);
        }
        this.fixedTiles = this.dashboardService.getTiles();
      },
      noop,
      noop
    ));

  }

  onBuilldingSelected(selected: Building){
    this.currentBuilding = selected;
    this.loadTemplates(selected.id, selected.application);
  }
}
