import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRoutes } from '../../config/api-routes';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { ResidentLogin } from '../shared/model/residentLogin.model';

@Injectable()
export class ResetPasswordService {
    constructor(private httpClient: HttpClient, private apiRoutes: ApiRoutes) { }

    public savePassword(confirmationCode: string, password: string, businessUrlKey: string): Observable<ResidentLogin> {
        return this.httpClient.post<ResidentLogin>(this.apiRoutes.RESET_PASSWORD, { confirmationCode, password, businessUrlKey }).pipe(
            catchError(error => {
                return throwError(null);
            })
        );
    }

    public getUserByPasswordResetCode(code: string): Observable<any> {
        return this.httpClient.get(this.apiRoutes.RESET_PASSWORD_GET_USER_BY_PASSWORD_RESSET_CODE + `?passwordResetCode=${code}`).pipe(
            catchError(error => {
                return of({ username: 'User' });
            })
        );
    }

    public checkPassword(residentLoginId: number, password: string): Observable<any>{
        return this.httpClient.post(this.apiRoutes.CHECK_PASSWORD, {residentLoginId, password}).pipe(
            catchError(error => {
                return throwError(null);
            })
        )
    }

    public confirmPasswordReset(email: string, businessUrlKey: string): Observable<any> {
        return this.httpClient.post(this.apiRoutes.CONFIRM_RESET_PASSWORD, { "username": email, "businessUrlKey": businessUrlKey });
      }
}
