import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {fromEvent} from "rxjs";
import {map, switchMap, tap} from "rxjs/internal/operators";

@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  protected connectionOffline$ = fromEvent(window, 'offline');
  protected connectionOnline$ = fromEvent(window, 'online');


  constructor(private router: Router) { }

  public monitorConnection() {
    this.connectionOffline$.pipe(
      tap(() => this.goToOfflinePage()),
      map(() => this.router.url),
      switchMap((previousUrl) => this.connectionOnline$.pipe(map(() => previousUrl))),
    )
      .subscribe((previousUrl) => this.router.navigateByUrl(previousUrl));
  }

  private goToOfflinePage() {
    this.router.navigateByUrl('offline-page');
  }
}
