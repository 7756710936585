import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocalizationService} from './modules/shared/service/localization.service';
import {AuthorizationService} from './modules/shared/service/authorization.service';
import {LoggerService} from './modules/shared/service/logger.service';
import {ActivatedRoute, NavigationStart, Router, Routes, RoutesRecognized} from '@angular/router';
import {filter, map, tap} from 'rxjs/internal/operators';
import {RequestErrorModalComponent} from '@npm.chapps.com/chapps.common.angular.library';
import {ManifestService} from './services/manifest.service';
import {UpdateService} from './services/update.service';
import {OfflineService} from './services/offline.service';
import {BuildingService} from './modules/shared/service/building-service';
import {Building} from './modules/shared/model/building';
import {noop, Observable} from 'rxjs';
import {ApplicationStyler} from './modules/shared/service/application-styler.service';
import {shareReplay} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild(RequestErrorModalComponent) errorModal: RequestErrorModalComponent;
  public areTranslationsLoading: boolean;
  public fontFamily: string;
  public currentBuilding: Building;
  public areBuildingsLoading: boolean;
  public hideNavigation = false;

  constructor(
    private translate: TranslateService,
    private localizationService: LocalizationService,
    private authorizationService: AuthorizationService,
    private loggerService: LoggerService,
    private router: Router,
    private route: ActivatedRoute,
    private applicationStyler: ApplicationStyler,
    private manifestService: ManifestService,
    private updateService: UpdateService,
    private offlineService: OfflineService,
    private buildingService: BuildingService
  ) { }

  ngOnInit() {
    this.areBuildingsLoading = false;
    this.offlineService.monitorConnection();
    this.updateService.checkForUpdates();
    // @TODO Reintroduce loading of the Manifest
    this.applicationStyler.autoApply();
    this.areTranslationsLoading = true;
    this.translate.use(this.localizationService.getStartingLanguage()).subscribe(() => this.areTranslationsLoading = false);
    this.initErrorModal();
    this.localizationService.init();
    if (this.authorizationService.isUserLoggedIn()) {
      this.loadData();
    }
    this.router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe(e => this.logoutIfRequired(e as NavigationStart));

    this.isPreview().subscribe(preview => this.hideNavigation = preview);
  }

  private logoutIfRequired(event: NavigationStart) {
    const isLogoutRequired = event.url.indexOf('/set-password/') >= 0;

    if (isLogoutRequired) {
      this.authorizationService.clearSession();
    }
  }

  public isUserLoggedIn(): boolean {
    return this.authorizationService.isUserLoggedIn();
  }

  public isPreview(): Observable<boolean> {
    return this.router.events.pipe(
      filter(event => event instanceof RoutesRecognized),
      map((event: RoutesRecognized) => event.state.root.firstChild.data?.preview || false),
      shareReplay(1),
    );
  }

  private initErrorModal() {
    this.loggerService.error$.subscribe(() => this.errorModal.show());
  }

  private loadData() {
    this.areBuildingsLoading = true;
    this.buildingService.getBuildings().subscribe(
      buildings => {
        this.currentBuilding = buildings[0];
      },
      noop,
      () => this.areBuildingsLoading = false
    );
  }
}
