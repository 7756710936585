import {Injectable} from '@angular/core';
import {noop, Subject} from 'rxjs';
import {ErrorLogService} from './error-log.service';
import {ApplicationError} from '../model/application-error';
import {environment} from '../../../../environments/environment';

@Injectable()
export class LoggerService {

  public error$: Subject<any> = new Subject<any>();

  constructor(private errorLogService: ErrorLogService) {}

  public httpRequestError(status: number, method: string, urlWithParams: string, body: string, showModal: boolean = true) {
    const error = new ApplicationError(`${status} | ${method} | ${urlWithParams}`, [body], new Date());
    console.error(error.message);
    environment.name !== 'Local' ? this.errorLogService.sendError(error) : noop();
    showModal ? this.error$.next(null) : noop();
  }

  public clientError(errorName: string, route: string, message: string, stackTrace: any[]) {
    const error = new ApplicationError(`${errorName} | ${route} | ${message}`, stackTrace, new Date());
    console.error(error);
    environment.name !== 'Local' ? this.errorLogService.sendError(error) : noop();
  }
}

