import { Injectable } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router, RoutesRecognized} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/internal/operators';
import {take} from 'rxjs/operators';

const BUSINESS_KEY = 'businessId';
const BUSINESS_URL_KEY = 'businessUrlKey';

@Injectable({
  providedIn: 'root'
})
export class BusinessIdentifier {

  private firstRouteActivated$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    take(1),
  );
  private params$: Observable<[Params, Params]> = this.firstRouteActivated$.pipe(
    switchMap(() => combineLatest([this.route.paramMap, this.route.queryParams]))
  );

  public businessUrlKey$: Observable<string> = this.params$.pipe(
    map(([routeParams, queryParams]) => routeParams[BUSINESS_URL_KEY] || queryParams[BUSINESS_URL_KEY])
  );

  public businessId$: Observable<string> = this.params$.pipe(
    map(([routeParams, queryParams]) => routeParams[BUSINESS_KEY] || queryParams[BUSINESS_KEY])
  );


  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

}
