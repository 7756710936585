<div class="login-window">
  <div class="container-fluid d-flex flex-column justify-content-center align-items-center" (keyup)="onKeyUp($event)">
    <img [src]="logoUrl$ | async"
         alt="Company logo"/>
    <mdb-card id="login-card" class="m-3">
      <mdb-card-body class="pb-2">
        <h5  id="app-name" class="text-center mb-4">
          {{ applicationName$ | async | translate }}
        </h5>
        <form #form="ngForm">
          <input type="email" id="emailInput" name="emailInput" class="form-control mb-4" (focus)="onInputFocus()"
                 [(ngModel)]="model.email"
                 [placeholder]="Localization.Email | translate">
          <input type="password" id="passwordInput" name="passwordInput" class="form-control mb-2" (focus)="onInputFocus()"
                 [(ngModel)]="model.password"
                 [placeholder]="Localization.Password | translate">
        </form>
        <app-error-alert *ngIf="isAlertVisible" [message]="Localization.WrongEmailOrPassword" [classes]="['mb-0']"></app-error-alert>
        <app-error-alert *ngIf="isAccountNotConfirmed" [message]="Localization.AccountNotConfirmed" [classes]="['mb-0']"></app-error-alert>
      </mdb-card-body>
      <mdb-card-footer class="text-center">
        <button *ngIf="!isProccessing" type="button" class="mb-2 primary"
                mdbBtn
                                brightness="normal"
                [block]="true"
                (click)="onSubmit()"
                mdbWavesEffect
                [translate]="Localization.Login">
        </button>
        <button *ngIf="!isProccessing"
                color="link" type="button" mdbBtn

                (click)="goToForgotPasswordPage()"
                [translate]="Localization.ForgotPassword">
        </button>
        <mdb-spinner *ngIf="isProccessing"></mdb-spinner>
      </mdb-card-footer>
    </mdb-card>
  </div>
</div>
