<div id="photo-viewer" *ngIf="visible">
  <h1 class="white-text clickable" (click)="hide()" ><i class="fa fa-close"></i></h1>
  <img class="img-thumbnail d-block" [src]="activePhoto" alt="Photo">
  <h3 id="arrow-left" class="white-text clickable" (click)="prev()" *ngIf="!isFirst()">
    <i class="fa fa-lg fa-chevron-left"></i>
  </h3>
  <h3 id="arrow-right" class="white-text clickable" (click)="next()" *ngIf="!isLast()">
    <i class="fa fa-lg fa-chevron-right"></i>
  </h3>
</div>
