import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { BaseComponent } from 'src/app/modules/shared/components/base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-announcement-not-found-modal',
  templateUrl: './announcement-not-found-modal.component.html',
  styleUrls: ['./announcement-not-found-modal.component.css']
})
export class AnnouncementNotFoundModalComponent extends BaseComponent implements OnInit {

  @ViewChild('modal') modal: ModalDirective;
  
  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
  }

  public show() {
    this.modal.show();
  }

  goToAnnouncements() {
    this.modal.hide();
    this.router.navigate(['/announcements']);
  }

}
