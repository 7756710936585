<ng-container *ngIf="!areDetailsLoading && !notFound">
  <chapps-header [label]="Localization.Details"></chapps-header>
  <div class="mt-3 mb-0 mb-sm-5">
    <chapps-icon-label [icon]="'fa-info-circle'" [label]="details.title"></chapps-icon-label>
    <div class="pre-wrap">
      <chapps-icon-label *ngIf="!isDescriptionEmpty()" [icon]="'fa-comment'" [label]="details.description">
      </chapps-icon-label>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <chapps-icon-label [icon]="'fa-building'" [label]="details.buildingName">
      </chapps-icon-label>
    </div>
    <div class="col-6">
      <chapps-icon-label [icon]="'fa-user'" [label]="details.creatorFirstName + ' ' + details.creatorLastName">
      </chapps-icon-label>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <chapps-icon-label [icon]="'fa-play-circle'" [label]="details.from | date: applicationConfig.DATE_FORMAT">
      </chapps-icon-label>
    </div>
    <div class="col-6">
      <chapps-icon-label [icon]="'fa-stop-circle'" [label]="details.to | date: applicationConfig.DATE_FORMAT">
      </chapps-icon-label>
    </div>
  </div>
  <ng-container *ngIf="!isMediaEmpty()">
    <hr>
    <div id="images-container">
      <chapps-image *ngFor="let m of details.multimedia" [id]="m.announcementMultimediumId"
        [url]="getPictureOrPDFIcon(m)" [deletable]="false" (clicked)="onMediaClick(m)">
      </chapps-image>
    </div>
  </ng-container>
</ng-container>
<app-announcement-not-found-modal></app-announcement-not-found-modal>
<app-card-spinner [show]="areDetailsLoading"></app-card-spinner>