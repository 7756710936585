import {BaseComponent} from '../shared/components/base.component';
import {Component, OnInit } from '@angular/core';
import { AnnouncementsService } from '../shared/service/announcements.service';
import { AnnouncementView } from '../shared/model/announcement';
import { ApplicationConfig } from 'src/app/config/application.config';
import { PhotoViewerService } from 'src/app/components/photo-viewer/photo-viewer.service';
import { AnnouncementMultimedium } from '../shared/model/announcement-multimedium';
import { LocalStorageService } from '../shared/service/local-storage.service';
import {ApplicationStyler} from '../shared/service/application-styler.service';

@Component({
  selector: 'app-user-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
})
export class AnnouncementsComponent extends BaseComponent implements OnInit {


  public announcements: AnnouncementView[];
  public announcementsLoading: boolean = true;
  public isListEmpty: boolean = false;

  constructor(private announcementsService: AnnouncementsService,
    public applicationConfig: ApplicationConfig, private photoViewerService: PhotoViewerService, public styler: ApplicationStyler) {
    super();
    this.styler.pageName.next(this.Localization.Announcements);
  }

  ngOnInit() {
    this.announcements = [];
    this.loadAnnouncements();
  }

  private loadAnnouncements(){
    this.announcementsLoading = true;
    const now = new Date();
    return this.announcementsService.getAnnouncements().subscribe((result: AnnouncementView[]) => {
      result.forEach((element: AnnouncementView) => {
        if(new Date(element.to) > now || element.to === undefined){
          element.mediaColor = "primary";
        }else {
          element.mediaColor = "done";
        }
      })
      result.length == 0 ? this.isListEmpty = true : this.isListEmpty = false;
      this.announcements = result;
      this.announcementsLoading = false;
    })
  }

  public onPhotoClick(photos: AnnouncementMultimedium[], url: string) {
    this.photoViewerService.show(
      photos.map(photo => photo.url),
      photos.findIndex(photo => photo.url === url)
    );
  }
}
