import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/internal/operators';
import {Document} from '../model/document';
import {HttpClient, HttpParams} from '@angular/common/http';
import { ApiRoutes } from 'src/app/config/api-routes';

@Injectable()
export class DocumentsService {

  constructor(private httpClient: HttpClient, private apiRoutes: ApiRoutes) {}

  public getDocuments(): Observable<Document[]> {
    return this.httpClient.get<Document[]>(this.apiRoutes.DOCUMENTS).pipe(
      catchError(error => {
        return of([]);
      })
    );
  }
}
