<div class="photo-container" [ngStyle]="getContainerStyle()">
  <div class="outer rounded" [ngStyle]="getOuterStyle()" [class.z-depth-1]="shadow">
    <a *ngIf="deleteButton" (click)="deleteClicked($event)" type="button" mdbBtn floating="true" size="sm" color="email" class="waves-light delete-btn" mdbWavesEffect>
      <i class="fa fa-times"></i>
    </a>
    <div class="inner">
      <img [src]="url" [alt]="'photo'" [height]="size">
    </div>
  </div>
</div>


