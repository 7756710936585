<div class="forgot-password-window">
  <div class="container-fluid d-flex flex-column justify-content-center align-items-center">
    <img [src]="logoUrl$ | async" alt="Company logo"/>
    <mdb-card id="forgot-password-card" class="m-3">
      <mdb-card-body>
        <h5  class="text-center mb-4" [translate]="Localization.ResetPassword"></h5>
        <form #form="ngForm">
          <input type="email" id="emailInput" name="emailInput" class="form-control mb-2"
                 [(ngModel)]="model"
                 [placeholder]="Localization.Email | translate">
        </form>
        <div *ngIf="isAlertVisible" class="alert alert-success mb-0" role="alert"
             [translate]="Localization.ResetPasswordSuccess"></div>
      </mdb-card-body>
      <mdb-card-footer class="text-center">
        <button *ngIf="!isProccessing" type="button" class="mb-2 primary"
                mdbBtn
                                brightness="normal"
                [block]="true"
                (click)="onSubmit()"
                mdbWavesEffect
                [translate]="Localization.Reset">
        </button>
        <button *ngIf="!isProccessing"
                color="link" type="button" mdbBtn

                (click)="goBack()"
                [translate]="Localization.Back">
        </button>
        <mdb-spinner *ngIf="isProccessing"></mdb-spinner>
      </mdb-card-footer>
    </mdb-card>
  </div>
</div>
