import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardComponent} from './modules/dashboard/dashboard.component';
import {LoginComponent} from './modules/login/login.component';
import {ForgotPasswordComponent} from './modules/forgot-password/forgot-password.component';
import {SetPasswordComponent} from './modules/set-password/set-password.component';
import {LoggedInGuard} from './guards/logged-in.guard';
import {NotLoggedInGuard} from './guards/not-logged-in.guard';
import {NotificationsComponent} from './modules/notifications/notifications.component';
import {NotificationComponent} from './modules/notifications/details/notification.component';
import { DocumentsComponent } from './modules/documents/documents.component';
import { ResetPasswordComponent } from './modules/reset-password/reset-password.component';
import { AnnouncementsComponent } from './modules/announcements/announcements.component';
import {AnnouncementComponent} from './modules/announcements/details/announcement.component';
import {ErrorPageComponent} from '@npm.chapps.com/chapps.common.angular.library';
import { OfflinePageComponent } from './components/offline-page/offline-page.component';
import { OfflineGuard } from './guards/offline.guard';
import { MyAccountComponent } from './modules/my-account/my-account.component';
import {
  NewNotificationPreviewComponent
} from './modules/application-style-preview/new-notification-preview/new-notification-preview.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'offline-page', component: OfflinePageComponent },
  { path: 'error-page', component: ErrorPageComponent },
  { path: 'login/:businessUrlKey', component: LoginComponent, canActivate: [OfflineGuard, NotLoggedInGuard]  },
  { path: 'login', component: LoginComponent, canActivate: [OfflineGuard, NotLoggedInGuard]  },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [OfflineGuard] },
  { path: 'reset-password/:culture/:passwordResetCode', component: ResetPasswordComponent, canActivate: [OfflineGuard] },
  { path: 'set-password/:culture/:confirmationCode', component: SetPasswordComponent, canActivate: [OfflineGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [OfflineGuard, LoggedInGuard] },
  { path: 'new-notification', loadChildren: () => import('./modules/new-notification/new-notification.module').then(m => m.NewNotificationModule), canActivate: [OfflineGuard, LoggedInGuard]},
  { path: 'notifications', component: NotificationsComponent, canActivate: [OfflineGuard, LoggedInGuard], children:
      [
        { path: ':id', component: NotificationComponent, canActivate: [OfflineGuard]}
      ]
  },
  { path: 'my-account', component: MyAccountComponent, canActivate: [OfflineGuard, LoggedInGuard] },
  { path: 'documents', component: DocumentsComponent, canActivate: [OfflineGuard, LoggedInGuard] },
  {
    path: 'announcements', component: AnnouncementsComponent, canActivate: [OfflineGuard, LoggedInGuard], children:
      [
        {path: ':id', component: AnnouncementComponent, canActivate: [OfflineGuard]}
      ],
  },
  { path: 'preview', loadChildren: () => import('./modules/application-style-preview/application-style-preview.module').then(m => m.ApplicationStylePreviewModule), data: {preview: true} },
  { path: ':businessUrlKey', redirectTo: '/login/:businessUrlKey'},
  { path: '**', redirectTo: '/login'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


