import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../shared/components/base.component';
import {LoginService} from './login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthorizationService} from '../shared/service/authorization.service';
import {LoginResponse} from '../shared/model/login-response';
import {ApplicationErrorCode} from '../shared/enums/application-error-code';
import {ApplicationStyler} from '../shared/service/application-styler.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService]
})
export class LoginComponent extends BaseComponent implements OnInit {

  public model: { email: string, password: string };
  public isAlertVisible: boolean;
  public isAccountNotConfirmed: boolean;
  public isProccessing: boolean;
  public logoUrl$ = this.styler.logo$;
  public applicationName$ = this.styler.pageName$;

  constructor(private loginService: LoginService, private router: Router, private route: ActivatedRoute,
    private authService: AuthorizationService, private styler: ApplicationStyler) {
    super();
  }

  ngOnInit() {
    this.model = { email: '', password: '' };
    this.isAlertVisible = false;
    this.isProccessing = false;
    this.isAccountNotConfirmed = false;

  }

  public onInputFocus() {
    this.isAlertVisible = false;
    this.isAccountNotConfirmed = false;
  }

  public goToForgotPasswordPage() {
    this.router.navigate(['forgot-password'], { relativeTo: this.route.parent });
  }

  public onKeyUp(event) {
    if (event.key === 'Enter') {
      this.onSubmit();
    }
  }

  public onSubmit() {
    if (this.isNotNullOrEmpty(this.model.email) && this.isNotNullOrEmpty(this.model.password)) {
      this.isProccessing = true;
      this.loginService.login(this.model.email, this.model.password).subscribe(
        (response) => this.onLoginSuccess(response),
        error => {
          this.isAlertVisible = true;
          this.isProccessing = false;
        },
        () => this.isProccessing = false
      );
    }
  }

  private onLoginSuccess(response: LoginResponse) {
    this.isAlertVisible = false;
    this.isProccessing = false;
    this.isAccountNotConfirmed = false;
    switch (response.code) {
      case ApplicationErrorCode.OK:
        this.authService.initSession(response);
        this.router.navigateByUrl('dashboard');
        break;
      case ApplicationErrorCode.NOT_CONFIRMED:
        this.isAccountNotConfirmed = true;
        break;
      default:
        this.isAlertVisible = true;
        break;
    }
  }

  private isNotNullOrEmpty(value: string): boolean {
    return value != null && value !== '';
  }
}
