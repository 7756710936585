
<!-- SideNav slide-out button -->
<div  class="header" >
  <a *ngIf="showMenu" (click)="sidenav.toggle()"><span class="material-icons">menu</span></a>
  <a *ngIf="!showMenu" routerLink="/dashboard"><i class="fas fa-arrow-left" style="color: white"></i></a>
  <h5 class="my-2">{{ styler.pageName$ | async | translate}}</h5>
</div>
<!--/. SideNav slide-out button -->

<!-- Sidebar navigation -->
<mdb-side-nav #sidenav class="white" [fixed]="false">
  <!-- Logo -->
  <li class="top-nav">
    <a (click)="sidenav.hide()" class="arrow-back"><i class="fas fa-arrow-left"></i></a>
    <div class="user avatar">
      <figure class="placeholder">
        {{ userInitials }}
      </figure>
    </div>
    <div class="user-info">
      <span>{{getUsername()}}</span>
      <span *ngIf="currentBuilding">{{ currentBuilding.address | customAddress: "street"}}</span>
    </div>
  </li>
  <!--/. Logo -->
  <!-- Side navigation links -->

  <ng-container *ngIf="!isLanguagesShown; else languagesList">
    <li class="middle-nav">
      <ul class="ml-auto">
        <li>
          <a routerLink="/dashboard" (click)="sidenav.hide()">
            <span>{{Localization.Home | translate}}</span>
          </a>
        </li>
        <!--<li>
          <a routerLink="" (click)="sidenav.hide()">
            <span>{{Localization.MessageCentre | translate}}</span>
          </a>
        </li>-->
        <li>
          <a routerLink="/documents" (click)="sidenav.hide()">
            <span>{{Localization.Documents | translate}}</span>
          </a>
        </li>
        <!--<li>
          <a routerLink="" (click)="sidenav.hide()">
            <span>{{Localization.Contacts | translate}}</span>
          </a>
        </li>
        <li>
          <a routerLink="" (click)="sidenav.hide()">
            <span>{{Localization.EmergencyContacts | translate}}</span>
          </a>
        </li>-->
      </ul>
    </li>

    <li class="bottom-nav">
      <ul>
        <li>
          <a routerLink="" (click)="showLanguages()" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; padding-right: 20px;">
            <span>{{Localization.Language | translate}}</span>
            <span><i class="fas fa-angle-right"></i></span>
          </a>
        </li>
        <li>
          <a routerLink="/my-account" (click)="sidenav.hide()">
            <span>{{Localization.MyAccount | translate}}</span>
          </a>
        </li>
        <li>
          <a (click)="logout()">
            <span>{{Localization.Logout | translate}}</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="business-logo"
         *ngIf="styler.logo$ | async as logoUrl">
      <img [src]="logoUrl"
           alt="Application Logo"/>
    </li>
  </ng-container>
  <ng-template #languagesList>
    <a routerLink="" (click)="showLanguages()">
      <span style="padding-right: 15px;"><i class="fas fa-arrow-left"></i></span>
      <span>{{Localization.Language | translate}}</span>
    </a>
    <a *ngFor="let language of supportedLanguages$ | async" (click)="onLanguageClick(language)"
      [class.active]="isLanguageSelected(language)" [class.disabled]="isLanguageSelected(language)" class="languageList">
        <img [src]="flagService.getFlagFromCulture(language)"/>
        <span [translate]="getLanguageLocalization(language)"></span>
    </a>
  </ng-template>

  <!--/. Side navigation links -->
  <div class="sidenav-bg mask-strong"></div>
</mdb-side-nav>
<!--/. Sidebar navigation -->

<app-change-culture-confirmation-modal (confirmed)="onCultureChangeConfirmed($event)"></app-change-culture-confirmation-modal>
