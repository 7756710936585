import {ApplicationError} from '../model/application-error';
import {ApiRoutes} from '../../../config/api-routes';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {noop} from 'rxjs';
import {HttpHeadersService} from './http-headers.service';

@Injectable()
export class ErrorLogService {

  constructor(private httpClient: HttpClient, private routes: ApiRoutes, private headersService: HttpHeadersService) {}

  public sendError(error: ApplicationError) {
    const headers = this.headersService.addNoLogHeader(new HttpHeaders());
    this.httpClient.post(this.routes.ERROR_LOG, error, {headers: headers}).subscribe(noop);
  }
}
