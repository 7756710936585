import { PipeTransform, Pipe } from '@angular/core';
import { Address } from '../modules/shared/model/address';

@Pipe({
    name: 'customAddress',
    pure: false
})

export class CustomAddressPipe implements PipeTransform {

    constructor() { }

    transform(address: Address, extension?: string): string {

        if (address == null) {
          return;
        }

        switch (extension) {
            case ('street'):
                return this.streetAddress(address);
            case ('city'):
                return address.zipCode + " " + address.city;
            default:
              return (address.street || '')
                + (address.street ? " " : "")
                + address.houseNumber + ", " + address.zipCode + " " + address.city;

        }
    }

    protected streetAddress(address: Address) {
        if(address.street == null || address.houseNumber == null){
            return;
        }
        switch (address.country) {
            case ('BE'):
                return (address.street || '')  + " " + address.houseNumber;
            case ('FR'):
                return address.houseNumber + " " + address.street;
            default:
              return address.street + " " + address.houseNumber;
        }
    }
}
