<div *ngIf="!isDataLoading()">
  <div class="image-container">
    <div class="image-sub-container">
      <img [src]="currentBuilding.thumbnailUrl" class="currentImage">
      <div class="dropdown building-info" dropdown [class.caption]="!(isDesktop$ | async)" mdbDropdown>

          <div class="building-selector" mdbDropdownToggle>
            <a type="button" class="nav-link waves-light rounded" mdbWavesEffect >{{currentBuilding.label}}</a>
            <i class="fa fa-chevron-right"
               *ngIf="tenantOfMultipleBuildings"></i>
          </div>

        <div *mdbDropdownMenu class="dropdown-menu dropdown dropdown-primary dropdown-menu-right" role="menu" #buildingMenu>
          <a *ngFor="let building of buildings" (click)="onBuilldingSelected(building)" class="dropdown-item">{{building.label}}</a>
        </div>
        <div *ngIf="isDesktop$ | async" class="building-info-address">
          <p>
            {{ currentBuilding.address | customAddress: "street"}}</p>
          <p>
            {{ currentBuilding.address | customAddress: "city"}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="sub-container mx-auto">
      <h5  [translate]="Localization.ReportAProblem" class="mt-3" style="font-weight:200"></h5>
      <div *ngIf="doesUserHaveOneBuilding()" class="tiles-container mt-3">
        <app-dashboard-notification-tile *ngFor="let tile of dynamicTiles" [model]="tile"
                                         (selected)="onNotificationTileSelect($event)">
        </app-dashboard-notification-tile>
      </div>
      <app-error-alert *ngIf="!doesUserHaveAnyBuildings()" [message]="Localization.NoBuildingsMessage"></app-error-alert>
      <h5  [translate]="Localization.Communications" class="mt-3"></h5>
      <div class="mt-4 tiles-container">
        <app-dashboard-notification-tile *ngFor="let tile of fixedTiles" [model]="tile"
                                         (selected)="onFixedTileSelected($event)">
        </app-dashboard-notification-tile>
      </div>
    </div>
  </div>
</div>
<div class="page-spinner" *ngIf="isDataLoading()">
  <mdb-spinner spinnerType="big"></mdb-spinner>
</div>
