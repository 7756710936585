import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/internal/operators';
import {Announcement} from '../model/announcement';
import {HttpClient, HttpParams} from '@angular/common/http';
import { ApiRoutes } from 'src/app/config/api-routes';
import { HasAnyUnreadAnnouncements } from '../model/has-any-unread-announcements';

@Injectable()
export class AnnouncementsService {

  constructor(private httpClient: HttpClient, private apiRoutes: ApiRoutes) {
  }

  public getAnnouncements(): Observable<Announcement[]> {
    return this.httpClient.get<Announcement[]>(this.apiRoutes.ANNOUNCEMENTS).pipe(
      catchError(error => {
        return of([]);
      })
    );
  }

  public getAnnouncement(announcementId: number): Observable<Announcement> {
    return this.httpClient.get<Announcement>(this.apiRoutes.getRouteWithInjectedParams(this.apiRoutes.ANNOUNCEMENT, [announcementId.toString()])).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }

  public setAnnouncementToRead(announcementId: number): Observable<any> {
    return this.httpClient.post(this.apiRoutes.getRouteWithInjectedParams(this.apiRoutes.SET_ANNOUNCEMENT_TOREAD, [announcementId.toString()]), {}).pipe(
      catchError(error => {
        return of([]);
      })
    ); 
  }

  public hasResidentAnyUnreadAnnouncements(): Observable<HasAnyUnreadAnnouncements> {
    return this.httpClient.get<HasAnyUnreadAnnouncements>(this.apiRoutes.HAS_ANY_UNREAD_ANNOUNCEMENTS).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
