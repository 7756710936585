import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthorizationService} from '../../../shared/service/authorization.service';
import {LocalStorageService} from '../../service/local-storage.service';
import {ApplicationConfig} from '../../../../config/application.config';
import {Location} from '@angular/common';
import {Observable} from 'rxjs';
import {LocalizationService} from '../../service/localization.service';
import {ChangeCultureConfirmationModalComponent} from '../change-culture-confirmation-modal/change-culture-confirmation-modal.component';
import {RefreshTokenService} from '../../service/refresh-token.service';
import {Building} from '../../model/building';
import {SidenavComponent} from 'ng-uikit-pro-standard';
import {FlagService} from '../../service/flag.service';
import {TranslateService} from '@ngx-translate/core';
import {ApplicationStyler} from '../../service/application-styler.service';

@Component({
  selector: 'app-user-navbar',
  templateUrl: './user-navbar.component.html',
  styleUrls: ['./user-navbar.component.scss'],
})
export class UserNavbarComponent extends BaseComponent implements OnInit {

  @ViewChild(ChangeCultureConfirmationModalComponent) changeCultureConfirmationModal: ChangeCultureConfirmationModalComponent;
  @ViewChild('sidenav', { static: true }) sidenav: SidenavComponent

  @Input() currentBuilding: Building;

  public logoUrl: string;
  public isDashboardButtonActive: boolean;
  public isAccountButtonActive: boolean;
  public supportedLanguages$: Observable<string[]>
  public isLanguagesShown: boolean = false;
  public showMenu: boolean = true;
  constructor(protected router: Router, private authService: AuthorizationService,
              private localStorageService: LocalStorageService, private applicationConfig: ApplicationConfig, private location: Location,
              private localizationService: LocalizationService, private refreshTokenService: RefreshTokenService, public flagService: FlagService,
              private translateService : TranslateService, protected route: ActivatedRoute, public styler: ApplicationStyler) {
    super();
  }

  ngOnInit() {
    this.checkIfRouteIsDashboard();
    // @TODO Refactor into Application Styling
    // this.businessStyleService.businessStyleSettings.subscribe({
    //   next: (settings) => {
    //     this.logoUrl = settings.businessLogoUrl;
    //   }
    // });

    this.resetButtons();
    this.selectButtonDependingOfRoute(this.router.url);
    this.selectListItemDependingOnRoute(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkIfRouteIsDashboard();
        this.selectButtonDependingOfRoute(event.url);
        this.selectListItemDependingOnRoute(event.url);
      }
    });
    this.supportedLanguages$ = this.localizationService.getSupportedLanguages();
  }

  public onLanguageClick(value) {
    this.changeCultureConfirmationModal.show(value);
  }

  public onCultureChangeConfirmed(value) {
    this.localizationService.setCulture(value).subscribe(() => {
      this.refreshTokenService.refreshToken().subscribe(response => {
        if (response && response.token) {
          this.authService.initSession(response);
          window.location.reload();
        }
      });
    });
  }

  public isLanguageSelected(value: string): boolean {
    return value === this.localStorageService.getCulture();
  }

  public getLanguageLocalization(value: string): string {
    return 'lang_' + value.replace('-', '_');
  }

  public showLanguages(){
    this.isLanguagesShown = !this.isLanguagesShown;
  }

  public getUsername(): string {
    return `${this.localStorageService.getFirstname()} ${this.localStorageService.getLastname()}`;
  }

  public getUserEmail(): string {
    return this.localStorageService.getEmail();
  }


  public selectButtonDependingOfRoute(url: string) {
    this.isDashboardButtonActive = url.includes('dashboard');
    this.isAccountButtonActive = url.includes('preferences');
  }

  public selectListItemDependingOnRoute(url: string) {
  }

  public logout() {
    this.authService.clearSession();
    this.router.navigateByUrl('login');
  }

  public get userInitials(): string {
    return [this.localStorageService.getFirstname(), this.localStorageService.getLastname()]
      .map(name => name.charAt(0))
      .join('');
  }

  public goBack() {
    this.location.back();
  }

  private resetButtons() {
    this.isDashboardButtonActive = false;
    this.isAccountButtonActive = false;
  }

  private checkIfRouteIsDashboard(){
    if(this.router.url.includes('/documents') || this.router.url.includes('/announcements') || this.router.url.includes('/notifications')){
      this.showMenu = false;
    }else {
      this.showMenu = true
    }
  }


}
