import {Localization} from '../enums/localization';
import {Component} from '@angular/core';

@Component({
  selector: 'app-base-component',
  template: '<div></div>'
})
export class BaseComponent {
  public readonly Localization = Localization;
}
