import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiRoutes} from '../../../config/api-routes';
import {ResidentLogin} from '../model/residentLogin.model';
import {Observable} from 'rxjs';
import {BusinessIdentifier} from './business-identifier.service';
import {ApplicationStyleSettings} from '../model/application-style-settings';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStyleLoader {

  constructor(
    private httpClient: HttpClient,
    private apiRoutes: ApiRoutes,
  ) {
  }

  public get(reference: string): Observable<ApplicationStyleSettings> {
    return this.fetch(this.apiRoutes.APPLICATION_STYLE_SETTINGS_GET, reference);
  }

  public forBusiness(businessId: string): Observable<ApplicationStyleSettings> {
    return this.fetch(this.apiRoutes.APPLICATION_STYLE_SETTINGS_BUSINESS, `${businessId}`);
  }

  public forResident(resident: ResidentLogin): Observable<ApplicationStyleSettings> {
    return this.fetch(this.apiRoutes.APPLICATION_STYLE_SETTINGS_RESIDENT, `${resident.id}`);
  }

  public forBusinessUrlKey(businessUrlKey: string) {
    return this.fetch(this.apiRoutes.APPLICATION_STYLE_SETTINGS_BUSINESSURLKEY, `${businessUrlKey}`);
  }

  private fetch(endpoint: string, id: string): Observable<ApplicationStyleSettings> {
    return this.httpClient.get<ApplicationStyleSettings>(this.apiRoutes.getRouteWithInjectedParams(endpoint, [id]));
  }
}
