<app-card-spinner [show]="areIssueMessagesLoading"></app-card-spinner>
<app-issue-notification-chat *ngIf="!areIssueMessagesLoading" [messages]="messages"
                             [replyInProgress]="isReplyInProgress"></app-issue-notification-chat>
<div class="d-flex flex-row flex-wrap mb-3">
  <app-img-thumbnail [deleteButton]="true" (deleteClick)="deleteSelectedToUploadImage(file)" *ngFor="let file of files"
                     [url]="file.base64Image" size="75" [border]="false"
                     class="mt-4 mr-4">
  </app-img-thumbnail>
  <app-card-spinner id="multimedia-spinner" [show]="areFilesLoading"></app-card-spinner>
</div>
<div class="d-flex justify-content-between align-items-center mb-4">
  <div class="d-flex justify-content-between align-items-center message">

    <input class="message-input" [(ngModel)]="reply" [placeholder]="Localization.Message | translate"
           (keyup)="onKeyUp($event)">

    <i class="fas fa-paperclip icon" [ngClass]="{'second': (isDesktop$ | async), 'first': !(isDesktop$ | async)}"
       (click)="addFiles()"></i>
    <input type="file" accept='image/*' #file (change)="fileChangeEvent('file')" style="display: none" multiple>

    <ng-container *ngIf="!(isDesktop$ | async)">
      <i class="fas fa-camera icon second" (click)="openCamera()"></i>
      <input type="file" accept="image/*" #image (change)="fileChangeEvent('camera')" style="display: none" multiple
             capture="environment">
    </ng-container>
    <!--<chapps-secondary-button [disabled]="isReplyInProgress" [small]="true" [icon]="'fa-image'" (clicked)="addFiles()">
    </chapps-secondary-button>-->
  </div>
  <a class="material-icons send-button" (click)="onSendButtonClick()"
     [ngStyle]="{'color': reply ? '#FAA61A' : alternateSendButtonColor}"
     [class.is-inactive]="isSendButtonDisabled()">send</a>
</div>


<!--<chapps-success-button *ngIf="!isReplyInProgress" [label]="Localization.Send | translate"
                          [disabled]="isSendButtonDisabled()" [small]="true" (clicked)="onSendButtonClick()">
  </chapps-success-button>-->
<chapps-image-cropper-modal (fileCropped)="fileCropped($event)"></chapps-image-cropper-modal>


