import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthorizationService} from '../modules/shared/service/authorization.service';

@Injectable()
export class NotLoggedInGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthorizationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isUserLoggedIn()) {
      this.router.navigateByUrl('dashboard');
    } else {
      return true;
    }
  }
}

