import {ApiRoutes} from '../../../config/api-routes';
import {LocalStorageService} from './local-storage.service';
import {LoginResponse} from '../model/login-response';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class RefreshTokenService {
  constructor(private httpClient: HttpClient, private apiRoutes: ApiRoutes, private localStorageService: LocalStorageService) {}

  public refreshToken(): Observable<LoginResponse> {
    const refreshToken = this.localStorageService.getRefreshToken();
    const loginId = parseInt(this.localStorageService.getLoginId(), 10);
    return this.httpClient.post<LoginResponse>(this.apiRoutes.REFRESH_TOKEN, {refreshToken, loginId});
  }
}
