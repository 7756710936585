import {Injectable} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiRoutes} from '../../../config/api-routes';
import {catchError} from 'rxjs/internal/operators';

@Injectable()
export class LocalizationService {

  private supportedLanguages: string[];
  private supportedLanguages$: Observable<string[]>;

  constructor(private httpClient: HttpClient, private routes: ApiRoutes, private localStorage: LocalStorageService) {}

  public init() {
    this.initSupportedLanguages();
  }

  public getStartingLanguage() {
    let result = navigator.language;
    if (this.localStorage.getCulture()) {
      result = this.localStorage.getCulture();
    }
    return result;
  }

  public setCulture(culture: string): Observable<any> {
    const params = new HttpParams().set('culture', culture);
    return this.httpClient.get(this.routes.SET_CULTURE, { params: params }).pipe(
      catchError(error => {
        return of();
      })
    );
  }

  public getSupportedLanguages(): Observable<string[]> {
    return (this.supportedLanguages == null) ? this.supportedLanguages$ : of(this.supportedLanguages);
  }

  private initSupportedLanguages() {
    this.supportedLanguages$ = this.getSupportedLanguagesFromServer();
    this.supportedLanguages$.subscribe(response => this.supportedLanguages = response);
  }

  private getSupportedLanguagesFromServer(): Observable<string[]> {
    return this.httpClient.get<string[]>(this.routes.SUPPORTED_LANGUAGES).pipe(
      catchError(error => {
        return of([]);
      })
    );
  }
}

