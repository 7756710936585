import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../shared/components/base.component';
import {ResetPasswordService} from './reset-password.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApplicationConfig} from '../../config/application.config';
import {LoggerService} from '../shared/service/logger.service';
import {AuthorizationService} from '../shared/service/authorization.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    providers: [ResetPasswordService]
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {

    public culture: any;
    public password: string;
    public confirmPassword: string;
    public isProccessing: boolean;
    public isPasswordCreationDone: boolean;
    public isPasswordCreationError: boolean;
    public logoUrl: string;
    public passwordResetCode: string;
    public username: string;
    public isLoading: boolean;
    public businessUrlKey: string;

    constructor(private resetPasswordService: ResetPasswordService, private router: Router, private route: ActivatedRoute,
        public applicationConfig: ApplicationConfig,
        private loggerService: LoggerService, private authorizationService: AuthorizationService,
        private translateService: TranslateService) {
        super();
    }

    ngOnInit() {
        this.password = '';
        this.confirmPassword = '';
        this.isPasswordCreationDone = false;
        this.isPasswordCreationError = false;
        this.isProccessing = false;
        this.isLoading = true;
        // @TODO Refactor into Application Styling
        // this.businessStyleService.businessStyleSettings.subscribe({
        //     next: (settings) => {
        //       this.logoUrl = settings.businessLogoUrl;
        //     }
        //   });
        this.authorizationService.clearSession();
        this.route.params.subscribe(params => {
            this.passwordResetCode = params['passwordResetCode'];
            this.culture = params['culture'];
            this.culture = this.culture == null ? 'en-US' : this.culture;
            this.translateService.use(this.culture);
            this.getUserByPasswordResetCode();
        });
        // @TODO Refactor into Application Styling
        // this.businessStyleService.businessStyleSettings.subscribe({
        //     next: (settings) => {
        //       this.businessUrlKey = settings.businessUrlKey;
        //     }
        //   })
    }

    public onInputFocus() {
        this.isPasswordCreationError = false;
    }

    public areBothPasswordEqual(): boolean {
        return this.password === this.confirmPassword;
    }

    public doesPasswordHaveTargetedLenght(): boolean {
        return this.password.length >= this.applicationConfig.MIN_PASSWORD_LENGTH;
    }

    public goToLoginPage() {
        this.router.navigate(['login'], { relativeTo: this.route.parent });
    }

    public onSubmit() {
        this.isPasswordCreationError = false;
        this.isProccessing = true;
        this.resetPasswordService.savePassword(this.passwordResetCode, this.password, this.businessUrlKey).subscribe(
            () => this.isPasswordCreationDone = true,
            () => {
                this.isProccessing = false;
                this.isPasswordCreationError = true;
            },
            () => this.isProccessing = false
        );
    }

    private getUserByPasswordResetCode() {
        this.resetPasswordService.getUserByPasswordResetCode(this.passwordResetCode).subscribe(
            user => {
                this.isLoading = false;
                this.username = user.username;
            }
        );
    }
}
