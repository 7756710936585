<ng-container *ngIf="!isLoading">
  <hr *ngIf="!isListEmpty()">
  <div id="images-container">
    <chapps-image *ngFor="let photo of photos"
                  [id]="photo.id"
                  [url]="photo.url"
                  [deletable]="false"
                  (clicked)="onPhotoClick(photo.url)">
    </chapps-image>
  </div>
</ng-container>

<app-card-spinner [show]="isLoading"></app-card-spinner>
<hr>
