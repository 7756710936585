import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base.component';
import {ActivatedRoute} from '@angular/router';
import {IssueNotificationService} from '../../../../shared/service/issue-notification.service';
import {PhotoViewerService} from '../../../../../components/photo-viewer/photo-viewer.service';
import {Photo} from '../../../../shared/model/photo';

@Component({
  selector: 'app-notification-media',
  templateUrl: './notification-media.component.html',
  styleUrls: ['./notification-media.component.scss'],
})
export class NotificationMediaComponent extends BaseComponent implements OnInit {
  public photos: Photo[];
  public isLoading: boolean;
  private notificationId: number;

  constructor(private issueNotificationsService: IssueNotificationService, private route: ActivatedRoute,
              private photoViewerService: PhotoViewerService) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.init();
      this.notificationId = parseInt(params['id'], 10);
      this.getMedia(this.notificationId);
    });
  }

  public init() {
    this.photos = [];
  }

  public onPhotoClick(url: string) {
    this.photoViewerService.show(
      this.photos.map(photo => photo.url),
      this.photos.findIndex(photo => photo.url === url)
    );
  }

  public getMedia(id: number) {
    this.isLoading = true;
    this.issueNotificationsService.getIssueNotificationMedia(id).subscribe(
      response => this.photos = response,
      () => this.isLoading = false,
      () => this.isLoading = false
    );
  }

  public isListEmpty(): boolean {
    return !this.photos.length;
  }
}


