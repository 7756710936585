import {SeverityType} from '../enums/enums';

export class ApplicationError {
  constructor(
    public message: string,
    public stackTrace: any[],
    public createdOn: Date,
    public severityType: SeverityType = SeverityType.ERROR
  ) { }
}
