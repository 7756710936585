import {Component, ViewChild, EventEmitter, Output} from '@angular/core';
import {BaseComponent} from '../base.component';
import {ModalDirective} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-change-culture-confirmation-modal',
  templateUrl: './change-culture-confirmation-modal.component.html',
  styleUrls: ['./change-culture-confirmation-modal.component.scss'],
})
export class ChangeCultureConfirmationModalComponent extends BaseComponent {

  @ViewChild('modal') modal: ModalDirective;
  @Output() confirmed: EventEmitter<string> = new EventEmitter<string>();

  public newLanguage: string;
  public confirmationMessage: string;

  constructor() {
    super();
  }

  public show(value) {
    this.newLanguage = value;
    this.modal.show();
  }

  public confirm() {
    this.confirmed.emit(this.newLanguage);
    this.modal.hide();
  }
}
