import { Observable, of, throwError } from 'rxjs';
import { IssueNotification } from '../model/issue-notification';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiRoutes } from '../../../config/api-routes';
import { catchError, delay, map } from 'rxjs/internal/operators';
import { IssueNotificationMessage } from '../model/issue-notification-message';
import { IssueNotificationTemplate } from '../model/issue-notification-template';
import { IssueNotificationDetails } from '../model/issue-notification-details';
import { NewIssueNotification } from '../model/new-issue-notification';
import { Photo } from '../model/photo';
import { ApplicationType } from '../enums/enums';
import { toISOString } from 'src/app/helpers/dateFormatters';
import { Localization } from '../enums/localization';

@Injectable()
export class IssueNotificationService {

  private myUnitLocationsAndElements: IssueNotificationTemplate[];

  constructor(private httpClient: HttpClient, private apiRoutes: ApiRoutes) {
  }

  public getNotifications(httpParams?: HttpParams, pageNumber?: number, pageSize?: number): Observable<IssueNotification[]> {
    return this.httpClient.get<IssueNotification[]>(this.apiRoutes.ISSUE_NOTIFICATION).pipe(
      catchError(error => {
        return of([]);
      })
    );
  }

  public getIssueNotificationDetails(issueNotificationId: number): Observable<IssueNotificationDetails> {
    return this.httpClient.get<IssueNotificationDetails>(
      this.apiRoutes.getRouteWithInjectedParams(this.apiRoutes.ISSUE_NOTIFICATION_DETAILS, [issueNotificationId.toString()]))
      .pipe(
        catchError(error => {
          return of({} as IssueNotificationDetails);
        })
      );
  }

  public getIssueNotificationMedia(issueNotificationId: number): Observable<Photo[]> {
    return this.httpClient.get<Photo[]>(
      this.apiRoutes.getRouteWithInjectedParams(this.apiRoutes.ISSUE_NOTIFICATION_MEDIA, [issueNotificationId.toString()]))
      .pipe(
        catchError(error => {
          return of([]);
        })
      );
  }

  public getIssueNotificationMessages(issueNotificationId: number): Observable<IssueNotificationMessage[]> {
    return this.httpClient.get<IssueNotificationMessage[]>(
      this.apiRoutes.getRouteWithInjectedParams(this.apiRoutes.ISSUE_NOTIFICATION_MESSAGES, [issueNotificationId.toString()]))
      .pipe(
        catchError(error => {
          return of([]);
        })
      );
  }

  public sendIssueNotificationMessage(message: string, issueNotificationId: number, multimedia: File[]): Observable<IssueNotificationMessage> {
    const HttpUploadOptions = { headers: new HttpHeaders({ 'Accept': 'application/json' }) };
    const formData = new FormData();
    multimedia.forEach(file => {
      formData.append('files', file, file.name);
    });
    formData.append('value', message);
    formData.append('sentOn', toISOString(new Date()));
    const url = this.apiRoutes.getRouteWithInjectedParams(this.apiRoutes.ISSUE_NOTIFICATION_MESSAGES, [issueNotificationId.toString()]);
    return this.httpClient.post<IssueNotificationMessage>(url, formData, HttpUploadOptions)
      .pipe(
        catchError(error => {
          return of({ id: 0, value: 'error', sentOn: new Date().toISOString(), createdOn: new Date().toISOString(), sender: 'System' } as IssueNotificationMessage);
        })
      );
  }

  public getMyUnitLocationsAndElements(): Observable<IssueNotificationTemplate[]> {
    return of(this.myUnitLocationsAndElements).pipe(delay(1000));
  }

  public createNewIssueNotification(newIssueNotification: NewIssueNotification) {
    const HttpUploadOptions = { headers: new HttpHeaders({ 'Accept': 'application/json' }) };
    const formData = new FormData();
    newIssueNotification.multimedia.forEach(file => {
      formData.append('files', file, file.name);
    });
    formData.append('issueNotification.buildingId', newIssueNotification.buildingId.toString());
    formData.append('issueNotification.structureId', newIssueNotification.structureId.toString());
    formData.append('issueNotification.title', newIssueNotification.title);
    if (newIssueNotification.description) {
      formData.append('issueNotification.description', newIssueNotification.description);
    }
    if (newIssueNotification.locationLabel) {
      formData.append('issueNotification.locationLabel', newIssueNotification.locationLabel);
    }
    formData.append('issueNotification.application', newIssueNotification.application);
    formData.append('issueNotification.isInPrivateUnit', newIssueNotification.isInPrivateUnit.toString());

    return this.httpClient.post(this.apiRoutes.ISSUE_NOTIFICATION_ADD, formData, HttpUploadOptions)
      .pipe(
        catchError(error => {
          return throwError({});
        }));
  }

  public getTemplates(buildingId: number, application: string, parentId: number): Observable<IssueNotificationTemplate[]> {
    let params = new HttpParams().set('buildingId', buildingId.toString()).set('application', application);
    if (parentId) {
      params = params.append('parentNodeId', parentId.toString());
    }
    return this.httpClient.get<IssueNotificationTemplate[]>(this.apiRoutes.ISSUE_NOTIFICATION_TEMPLATES, { params: params })
      .pipe(
        map(templates => templates.map((template) => {
          template.icon = (template.icon != null) ? template.icon : 'UnitProblem';
          return template;
        })),
        catchError(error => {
          return of([]);
        })
      );
  }

  public getTemplatesByTemplatesIds(buildingId: number, application: ApplicationType,
    templatesIds: number[]): Observable<IssueNotificationTemplate[]> {
    const params = new HttpParams()
      .set('buildingId', buildingId.toString())
      .set('application', application)
      .set('templatesIds', templatesIds.join(', '));
    return this.httpClient.get<IssueNotificationTemplate[]>(this.apiRoutes.ISSUE_NOTIFICATION_TEMPLATES_BY_IDS, { params: params })
      .pipe(
        map(templates => templates.map(template => {
          template.icon = (template.icon != null) ? template.icon : 'UnitProblem';
          return template;
        })),
        catchError(error => {
          return of([]);
        })
      );
  }

  public markMessagesAsRead(issueNotificationId: number): Observable<any> {
    return this.httpClient.post(this.apiRoutes.getRouteWithInjectedParams(this.apiRoutes.ISSUE_NOTIFICATION_MESSAGES_MARK_AS_READ, [issueNotificationId.toString()]), {}).pipe(
      catchError(error => {
        return of([]);
      })
    );
  }

  public getNotificationIdWithNewestUnreadMessage(): Observable<number> {
    return this.httpClient.get<boolean>(this.apiRoutes.ISSUE_NOTIFICATION_MESSAGES_GET_NOTIFICATION_ID_WITH_NEWEST_UNREAD_MESSAGE).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}



