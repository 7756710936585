
export class LocalStorageService {

  readonly TOKEN_TAG: string = 'token';
  readonly CULTURE_TAG: string = 'culture';
  readonly REFRESH_TOKEN_TAG: string = 'refreshToken';
  readonly LOGIN_ID_TAG: string = 'loginId';
  readonly EXPIRE_TAG: string = 'expire';
  readonly FIRST_NAME_TAG: string = 'firstName';
  readonly LAST_NAME_TAG: string = 'lastName';
  readonly EMAIL_TAG: string = 'email';
  readonly BUSINESS_LOGO_URL = 'businessLogoUrl';
  readonly PAGE_NAME = 'pageName';

  public setFirstname(value) {
    localStorage.setItem(this.FIRST_NAME_TAG, value);
  }

  public getFirstname(): string {
    return localStorage.getItem(this.FIRST_NAME_TAG);
  }

  public setLastname(value) {
    localStorage.setItem(this.LAST_NAME_TAG, value);
  }

  public getLastname(): string {
    return localStorage.getItem(this.LAST_NAME_TAG);
  }

  public setEmail(value) {
    localStorage.setItem(this.EMAIL_TAG, value);
  }

  public getEmail(): string {
    return localStorage.getItem(this.EMAIL_TAG);
  }

  public setRefreshToken(value) {
    localStorage.setItem(this.REFRESH_TOKEN_TAG, value);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN_TAG);
  }

  public doesTokenExist(): boolean {
    return this.getToken() != null;
  }

  public getToken(): any {
    return localStorage.getItem(this.TOKEN_TAG);
  }

  public setToken(value) {
    localStorage.setItem(this.TOKEN_TAG, value);
  }

  public setCulture(value) {
    localStorage.setItem(this.CULTURE_TAG, value);
  }

  public getCulture(): string {
    return localStorage.getItem(this.CULTURE_TAG);
  }
  public getLoginId(): string {
    return localStorage.getItem(this.LOGIN_ID_TAG);
  }

  public setLoginId(value) {
    localStorage.setItem(this.LOGIN_ID_TAG, value);
  }

  public setExpire(value) {
    localStorage.setItem(this.EXPIRE_TAG, value);
  }

  public deleteRefreshToken() {
    localStorage.removeItem(this.REFRESH_TOKEN_TAG);
  }

  public deleteToken() {
    localStorage.removeItem(this.TOKEN_TAG);
  }

  public deleteExpire() {
    localStorage.removeItem(this.EXPIRE_TAG);
  }

  public deleteLoginId() {
    localStorage.removeItem(this.LOGIN_ID_TAG);
  }

  public deleteFirstname() {
    localStorage.removeItem(this.FIRST_NAME_TAG);
  }

  public deleteLastname() {
    localStorage.removeItem(this.LAST_NAME_TAG);
  }

  public deleteEmail() {
    localStorage.removeItem(this.EMAIL_TAG);
  }

}
