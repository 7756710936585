import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {LoaderComponent} from './components/loader/loader.component';
import {LoaderService} from './components/loader/loader.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MDBBootstrapModulesPro, ToastModule} from 'ng-uikit-pro-standard';
import {AppRoutingModule} from './app-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SharedModule} from './modules/shared/shared.module';
import {ApplicationConfig} from './config/application.config';
import {ApiRoutes} from './config/api-routes';
import {LoggedInGuard} from './guards/logged-in.guard';
import {NotificationsComponent} from './modules/notifications/notifications.component';
import {DocumentsComponent} from './modules/documents/documents.component';
import {NotificationComponent} from './modules/notifications/details/notification.component';
import {DashboardComponent} from './modules/dashboard/dashboard.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {LoginComponent} from './modules/login/login.component';
import {ForgotPasswordComponent} from './modules/forgot-password/forgot-password.component';
import {SetPasswordComponent} from './modules/set-password/set-password.component';
import {ResetPasswordComponent} from './modules/reset-password/reset-password.component';
import {NotLoggedInGuard} from './guards/not-logged-in.guard';
import {environment} from 'src/environments/environment';
import {
  NotificationMessagesComponent
} from './modules/notifications/details/components/notification-messages/notification-messages.component';
import {NotificationDetailsComponent} from './modules/notifications/details/components/notification-details/notification-details.component';
import {NotificationMediaComponent} from './modules/notifications/details/components/notification-media/notification-media.component';
import {PhotoViewerComponent} from './components/photo-viewer/photo-viewer.component';
import {PhotoViewerService} from './components/photo-viewer/photo-viewer.service';
import {AnnouncementsComponent} from './modules/announcements/announcements.component';
import {ChappsCommonAngularLibraryModule, ChappsListModule} from '@npm.chapps.com/chapps.common.angular.library';
import {Localization} from './modules/shared/enums/localization';
import {DocumentListItemComponent} from './modules/documents/components/document-list-item/document-list-item.component';
import {AnnouncementListItemComponent} from './modules/announcements/components/annoucement-list-item/annoucement-list-item.component';
import {AnnouncementComponent} from './modules/announcements/details/announcement.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ILocalization} from '@npm.chapps.com/chapps.common.angular.library/lib/interface/ILocalization';
import {
  AnnouncementNotFoundModalComponent
} from './modules/announcements/details/components/announcement-not-found-modal/announcement-not-found-modal.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {UpdateModalComponent} from './components/update-modal/update-modal.component';
import {OfflinePageComponent} from './components/offline-page/offline-page.component';
import {CommonModule, registerLocaleData} from '@angular/common';
import {PipesModule} from './pipes/pipes.module';
import {FlagService} from './modules/shared/service/flag.service';
import {MyAccountComponent} from './modules/my-account/my-account.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import {MatDialogModule} from '@angular/material/dialog';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import {LocalStorageService} from './modules/shared/service/local-storage.service';

registerLocaleData(localeEn, 'en')
registerLocaleData(localeFr, 'fr')
registerLocaleData(localeDe, 'de')
registerLocaleData(localeNl, 'nl')

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.apiUrl + 'backend/translations/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    DashboardComponent,
    NotificationComponent,
    NotificationDetailsComponent,
    NotificationMediaComponent,
    NotificationMessagesComponent,
    DocumentsComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SetPasswordComponent,
    NotificationsComponent,
    ResetPasswordComponent,
    PhotoViewerComponent,
    AnnouncementsComponent,
    AnnouncementComponent,
    DocumentListItemComponent,
    AnnouncementListItemComponent,
    AnnouncementNotFoundModalComponent,
    UpdateModalComponent,
    OfflinePageComponent,
    MyAccountComponent,


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({loader: {provide: TranslateLoader, useFactory: (createTranslateLoader), deps: [HttpClient]}}),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    SharedModule.forRoot(),
    NgSelectModule,
    AngularSvgIconModule,
    ChappsListModule,
    ChappsCommonAngularLibraryModule.forRoot(Localization as ILocalization, '#001659', '#6a86d9'),
    ImageCropperModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    BrowserModule,
    CommonModule,
    PipesModule,
    MatExpansionModule,
    MatButtonModule,
    MatBadgeModule,
    MatDialogModule,
  ],
  providers: [
    LoaderService,
    ApplicationConfig,
    ApiRoutes,
    FlagService,
    LoggedInGuard,
    NotLoggedInGuard,
    PhotoViewerService,
    // {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {
      provide: LOCALE_ID,
      deps: [LocalStorageService],
      useFactory: (localStorageService: LocalStorageService) => localStorageService.getCulture()
    },

  ],
  bootstrap: [AppComponent],
  exports: [],
  entryComponents: [DocumentListItemComponent, AnnouncementListItemComponent]
})
export class AppModule { }

