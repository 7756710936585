import {environment} from '../../environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class ApiRoutes {
  readonly LOGIN: string = environment.apiUrl + 'backend/token/requestToken';
  readonly REFRESH_TOKEN: string = environment.apiUrl + 'backend/token/refreshToken';
  readonly BUILDINGS: string = environment.apiUrl + 'backend/building';
  readonly ISSUE_NOTIFICATION: string = environment.apiUrl + 'backend/issueNotification';
  readonly ISSUE_NOTIFICATION_DETAILS: string = environment.apiUrl + 'backend/issueNotification/{0}';
  readonly ISSUE_NOTIFICATION_MESSAGES: string = environment.apiUrl + 'backend/issueNotification/{0}/message';
  readonly ISSUE_NOTIFICATION_MEDIA: string = environment.apiUrl + 'backend/issueNotification/{0}/multimedia';
  readonly ISSUE_NOTIFICATION_ADD: string = environment.apiUrl + 'backend/issueNotification';
  readonly ISSUE_NOTIFICATION_ADD_MULTIMEDIA: string = environment.apiUrl + 'backend/issueNotification/{0}/multimedia';
  readonly ISSUE_NOTIFICATION_TEMPLATES: string = environment.apiUrl + 'backend/issueNotificationTemplate';
  readonly ISSUE_NOTIFICATION_TEMPLATES_BY_IDS: string = environment.apiUrl + 'backend/issueNotificationTemplate/byTemplatesIds';
  readonly ISSUE_NOTIFICATION_MESSAGES_MARK_AS_READ: string = environment.apiUrl + 'backend/issueNotification/{0}/markMessagesAsRead';
  readonly ISSUE_NOTIFICATION_MESSAGES_GET_NOTIFICATION_ID_WITH_NEWEST_UNREAD_MESSAGE: string = environment.apiUrl + 'backend/issueNotification/getNotificationIdWithNewestUnreadMessage';
  readonly ACCOUNT_GET_DETAILS: string = environment.apiUrl + 'backend/account';
  readonly SET_PASSWORD_GET_USER_BY_CONFIRMATION_CODE: string = environment.apiUrl + 'backend/account/getLoginByConfirmationCode';
  readonly RESET_PASSWORD_GET_USER_BY_PASSWORD_RESSET_CODE: string = environment.apiUrl + 'backend/account/getLoginByPasswordResetCode';
  readonly SET_PASSWORD: string = environment.apiUrl + 'backend/account/setPassword';
  readonly RESET_PASSWORD: string = environment.apiUrl + 'backend/account/resetPassword';
  readonly DOCUMENTS: string = environment.apiUrl + 'backend/document';
  readonly ERROR_LOG: string = environment.apiUrl + 'backend/errorHandling';
  readonly FORGOT_PASSWORD: string = environment.apiUrl + 'backend/account/forgotPassword';
  readonly CONFIRM_RESET_PASSWORD: string = environment.apiUrl + 'backend/account/confirmPasswordReset';
  readonly CHECK_PASSWORD: string = environment.apiUrl + 'backend/account/checkPassword';
  readonly LOCATION_TEMPLATES: string = environment.apiUrl + 'backend/location/templatesLocation';
  readonly SUPPORTED_LANGUAGES: string = environment.apiUrl + 'backend/translations/supportedcultures';
  readonly SET_CULTURE: string = environment.apiUrl + 'backend/account/setCulture';
  readonly ANNOUNCEMENTS: string = environment.apiUrl + 'backend/announcement';
  readonly ANNOUNCEMENT: string = environment.apiUrl + 'backend/announcement/{0}';
  readonly HAS_ANY_UNREAD_ANNOUNCEMENTS: string = environment.apiUrl + 'backend/announcement/hasAnyUnreadAnnouncements';
  readonly ANNOUNCEMENT_MuLTIMEDIA: string = environment.apiUrl + 'backend/announcement/{0}/multimedia/{1}';
  readonly SET_ANNOUNCEMENT_TOREAD: string = environment.apiUrl + 'backend/announcement/{0}/setAsRead';
  readonly APPLICATION_STYLE_SETTINGS_GET = environment.apiUrl + 'backend/applicationStyleSettings/[0]';
  readonly APPLICATION_STYLE_SETTINGS_RESIDENT = environment.apiUrl + 'backend/applicationStyleSettings/residentLogin/{0}';
  readonly APPLICATION_STYLE_SETTINGS_BUSINESS = environment.apiUrl + 'backend/applicationStyleSettings/business/{0}';
  readonly APPLICATION_STYLE_SETTINGS_BUSINESSURLKEY = environment.apiUrl + 'backend/applicationStyleSettings/businessUrlKey/{0}';
  readonly MANIFEST: string = environment.apiUrl + 'backend/manifest/{0}';
  readonly MANIFEST_FOR_BUSINESS: string = environment.apiUrl + 'backend/manifest/{0}/{1}';
  readonly STRUCTURES: string = environment.apiUrl + 'backend/structure/{0}/locations';
  readonly FILTERED_STRUCTURES: string = environment.apiUrl + 'backend/structure/{0}/locations/{1}/{2}';
  public DWELLINGS:string  = environment.apiUrl + 'backend/dwellings';


  public getRouteWithInjectedParams(route: string, values: (string|number)[]): string {
    let result: string = route;
    for (let i = 0; i < values.length; i++) {
      result = result.replace(`{${i}}`, values[i] as string);
    }
    return result;
  }
}
