import { Injectable } from '@angular/core';
import { ApiRoutes } from '../../../config/api-routes';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators';
import { ResidentLogin } from '../model/residentLogin.model';

@Injectable()
export class Accountservice {

    constructor(private httpClient: HttpClient, private apiRoutes: ApiRoutes) {}

    public getDetails(): Observable<ResidentLogin> {
      return this.httpClient.get<ResidentLogin>(this.apiRoutes.ACCOUNT_GET_DETAILS).pipe(
          catchError(error => {
            return throwError(error);
          })
      );
    }

    public setCulture(culture: string): Observable<any> {
        const params = new HttpParams().set('culture', culture);
        return this.httpClient.get(this.apiRoutes.SET_CULTURE, { params: params }).pipe(
            catchError(error => {
                return of();
            })
        );
    }
}
