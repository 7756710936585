import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IssueNotificationTemplate} from '../../model/issue-notification-template';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardTile} from '../../../dashboard/model/dashboard-tile';
import {Building} from '../../model/building';
import { HttpParams } from '@angular/common/http';
import { IssueNotificationStatus } from '../../enums/enums';
import { IssueNotification } from '../../model/issue-notification';
import { IssueNotificationService } from '../../service/issue-notification.service';

@Component({
  selector: 'app-dashboard-notification-tile',
  templateUrl: './dashboard-notification-tile.component.html',
  styleUrls: ['./dashboard-notification-tile.component.scss'],
})
export class DashboardNotificationTileComponent implements OnInit {

  @Input() model: IssueNotificationTemplate | DashboardTile;
  @Output() selected: EventEmitter<IssueNotificationTemplate | DashboardTile> = new EventEmitter<IssueNotificationTemplate | DashboardTile>();

  public numberOfUnreadMessages: number;
  constructor(private issueNotificationService: IssueNotificationService) {}
  
  ngOnInit(): void {
  }


  public onClick() {
    this.selected.emit(this.model);
  }

  private loadIssues(httpParams: HttpParams){
    this.issueNotificationService.getNotifications(httpParams).subscribe((result: IssueNotification[]) => {
      const issuesWithUnreadMessages = result.filter(iss => iss.hasUnreadMessage = true);
      this.numberOfUnreadMessages = issuesWithUnreadMessages.length;
    })
  }
}

