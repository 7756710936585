import {Injectable} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import {LoginResponse} from '../model/login-response';
import {TokenService} from './token.service';
import {TokenBody} from '../model/token-body';
import {TranslateService} from '../../../../../node_modules/@ngx-translate/core';
import {ReplaySubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  public readonly token$ = new ReplaySubject<TokenBody>(1);

  constructor(
    private localStorageService: LocalStorageService,
    private tokenService: TokenService,
    private translate: TranslateService,
  ) {
    this.token$.next(this.localStorageService.getToken());
  }

  public isUserLoggedIn(): boolean {
    return this.localStorageService.doesTokenExist();
  }

  public initSession(response: LoginResponse) {
    const decodedToken: TokenBody = this.tokenService.decodeToken(response.token);
    this.localStorageService.setRefreshToken(response.refreshToken);
    this.localStorageService.setToken(response.token);
    this.localStorageService.setLoginId(decodedToken.loginId);
    this.localStorageService.setExpire(decodedToken.exp);
    this.localStorageService.setFirstname(decodedToken.FirstName);
    this.localStorageService.setLastname(decodedToken.LastName);
    this.localStorageService.setEmail(decodedToken.email);
    this.localStorageService.setCulture(decodedToken.Culture);
    this.translate.use(this.localStorageService.getCulture()).subscribe();
    this.token$.next(decodedToken);
  }

  public clearSession() {
    this.localStorageService.deleteRefreshToken();
    this.localStorageService.deleteToken();
    this.localStorageService.deleteExpire();
    this.localStorageService.deleteLoginId();
    this.localStorageService.deleteFirstname();
    this.localStorageService.deleteLastname();
    this.localStorageService.deleteEmail();
    this.token$.next(null);
  }
}
