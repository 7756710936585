import {Injectable} from '@angular/core';
import {ApplicationStyleService} from './application-style.service';
import {merge, Observable, Subject} from 'rxjs';
import {shareReplay, startWith, take, takeUntil} from 'rxjs/operators';
import {camelCaseToKebabCase} from '../../../helpers/stringManipulation';
import {ApplicationStyleSettings} from '../model/application-style-settings';
import {ApplicationStyle} from '../model/application-style';
import {NavigationEnd, RouterEvent} from '@angular/router';
import {filter, map} from 'rxjs/internal/operators';
import {ApplicationConfig} from '../../../config/application.config';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStyler {

  private readonly stop$ = new Subject();
  public readonly manualStyling = new Subject<ApplicationStyleSettings>();
  private stylingSettings$ = merge(this.applicationStyle.settings$, this.manualStyling.asObservable());
  public readonly pageName = new Subject<string>();
  public readonly logo$: Observable<string> = this.stylingSettings$.pipe(
    map(settings => settings.style.businessLogoUrl ||ApplicationConfig.DEFAULT_LOGO_URL),
  );

  constructor(
    private applicationStyle: ApplicationStyleService,
  ) {}

  public readonly pageName$ = this.pageName.asObservable()
    .pipe(startWith('Residenz Website'), shareReplay(1));

  public initialStyling$ = this.stylingSettings$.pipe(take(1));

  private static applySigninBackground(style: ApplicationStyle) {
    if (style.signInBackgroundUrl) {
      ApplicationStyler.setCSSProperty('signInBackground', `url("${style.signInBackgroundUrl}")`);
    }
  }

  private static setCSSProperty(name: string, value: string) {
    name = name.startsWith('--') ? name : `--${name}`;
    document.body.style.setProperty(name, value);
  }

  public apply(settings: ApplicationStyleSettings) {
    this.updateCSSVariables(settings.style);
    this.updateApplicationName(settings.style.pwaName);
  }

  public autoApply() {
    this.stylingSettings$.pipe(
      takeUntil(this.stop$),
    ).subscribe(style => {
      this.apply(style);
    });
  }

  public stop() {
    this.stop$.next(null);
  }

  private autoMapColorVariables(style?: ApplicationStyle) {
    if (!style?.colors) {
      return;
    }
    const automapped = Object.keys(style.colors).map(setting => ({
      name: camelCaseToKebabCase(setting),
      value: style.colors[setting]
    }));

    automapped.forEach(color => ApplicationStyler.setCSSProperty(color.name, color.value));
  }

  private updateApplicationName(name: string) {
    if (name) {
      this.pageName.next(name);
    }
  }

  private updateCSSVariables(style: ApplicationStyle) {
    this.autoMapColorVariables(style);
    ApplicationStyler.applySigninBackground(style);
  }
}

function routeChanges(event: RouterEvent) {
  return event instanceof NavigationEnd;
}
