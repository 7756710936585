import {TokenBody} from '../model/token-body';

export class TokenService {
  constructor() {
  }

  public decodeToken(encodedToken: string): TokenBody {
    if (encodedToken != null) {
      const tokenParts: string[] = encodedToken.split('.');
      if (tokenParts[1] != null) {
        const decodedTokenJson = decodeURIComponent(Array.prototype.map.call(atob(tokenParts[1]), function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const parsedDecodedToken = JSON.parse(decodedTokenJson);
        parsedDecodedToken.email =  parsedDecodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
        return parsedDecodedToken;
      }
    }
    return null;
  }
}
