import { Component, OnInit, ViewChild } from '@angular/core';
import { noop } from 'rxjs';
import { ApplicationConfig } from '../../../config/application.config';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../shared/components/base.component';
import { Announcement } from '../../shared/model/announcement';
import { AnnouncementsService } from '../../shared/service/announcements.service';
import { PhotoViewerService } from '../../../components/photo-viewer/photo-viewer.service';
import { AnnouncementMultimedium } from '../../shared/model/announcement-multimedium';
import { AnnouncementNotFoundModalComponent } from './components/announcement-not-found-modal/announcement-not-found-modal.component';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent extends BaseComponent implements OnInit {

  @ViewChild(AnnouncementNotFoundModalComponent) notFoundModal: AnnouncementNotFoundModalComponent;

  public id: number;
  public details: Announcement;
  public areDetailsLoading: boolean;
  public notFound: boolean = false;

  constructor(private announcementsService: AnnouncementsService, private route: ActivatedRoute,
    private photoViewerService: PhotoViewerService, public applicationConfig: ApplicationConfig, private location: Location) {
    super();
  }

  ngOnInit() {
    this.details = {} as Announcement;
    this.route.params.subscribe(params => {
      this.id = parseInt(params['id'], 10);
      this.loadDetails();
    });
  }

  public onBackButtonClick() {
    this.location.back();
  }

  public loadDetails() {
    this.areDetailsLoading = true;
    this.announcementsService.getAnnouncement(this.id).subscribe(
      response => {
        if (response == null) {
          this.notFound = true;
          this.notFoundModal.show();
        }
        else this.details = response;
      },
      noop,
      () => this.areDetailsLoading = false
    );
  }

  public isDescriptionEmpty(): boolean {
    return this.details.description == null || this.details.description === '';
  }

  public isMediaEmpty(): boolean {
    return this.details.multimedia == null || this.details.multimedia.length === 0;
  }

  public onMediaClick(m: AnnouncementMultimedium) {
    if (m.type === 'Pdf') {
      window.open(m.url, '_blank');
    } else {
      this.photoViewerService.show([m.url], 0);
    }
  }

  public getPictureOrPDFIcon(m: AnnouncementMultimedium) {
    return (m.type === 'Pdf') ? this.applicationConfig.PDF_ICON_URL : m.url;
  }
}
