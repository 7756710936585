<ng-container *ngIf="!areIssueDetailsLoading">
  <chapps-header [label]="Localization.Details"></chapps-header>
  <div class="mt-3 mb-0 mb-sm-5">
    <chapps-icon-label [icon]="'fa-info-circle'" [label]="details.title"></chapps-icon-label>
    <chapps-icon-label *ngIf="!isDescriptionEmpty()"  [icon]="'fa-comment'" [label]="details.description">
    </chapps-icon-label>
  </div>
  <div class="row">
    <div *ngIf="!isLocationEmpty()" class="col-6">
      <chapps-icon-label [icon]="'fa-map-marker'" [label]="details.locationLabel">
      </chapps-icon-label>
    </div>
    <div class="col-6">
      <chapps-icon-label [icon]="'fa-spinner'" [label]="'issueNotificationStatus_' + details.status">
      </chapps-icon-label>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <chapps-icon-label [icon]="'fa-user'"
                         [label]="details.observerFirstName + ' ' +details.observerLastName">
      </chapps-icon-label>
    </div>
    <div class="col-6">
      <chapps-icon-label [icon]="'fa-calendar-alt'" [label]="details.observedOn | date: applicationConfig.DATE_TIME_FORMAT">
      </chapps-icon-label>
    </div>
  </div>
</ng-container>
<app-card-spinner [show]="areIssueDetailsLoading"></app-card-spinner>
