import {ApplicationStyle} from './application-style';

export class ApplicationStyleSettings {
  applicationStyleSettingsId?: number;
  reference?: string;
  businessId?: number;
  application?: string;
  name?: string;
  createdBy?: number;
  createdOn?: string;
  businessName?: string;
  businessEmail?: string;

  style = new ApplicationStyle();
}
