import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/modules/shared/components/base.component';

@Component({
  selector: 'app-offline-page',
  templateUrl: './offline-page.component.html',
  styleUrls: ['./offline-page.component.scss']
})
export class OfflinePageComponent extends BaseComponent implements OnInit {

  constructor(private router: Router) { super(); }

  ngOnInit(): void {
  }

  public reload() {
    this.router.navigateByUrl('/dashboard');
  }
}
