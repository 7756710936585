import { Component } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { BaseComponent } from 'src/app/modules/shared/components/base.component';

@Component({
  selector: 'app-update-modal',
  templateUrl: './update-modal.component.html',
  styleUrls: ['./update-modal.component.scss']
})
export class UpdateModalComponent extends BaseComponent {

  constructor(public modalRef: MDBModalRef) { super(); }

  public closeAndUpdate() {
    this.modalRef.hide();
    window.location.reload();
  }
}
