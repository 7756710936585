import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../shared/components/base.component';
import {ForgotPasswordService} from './forgot-password.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApplicationStyler} from '../shared/service/application-styler.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [ForgotPasswordService]
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {

  public model: string;
  public isAlertVisible: boolean;
  public isProccessing: boolean;
  public logoUrl$ = this.styler.logo$;
  public businessUrlKey: string;

  constructor(
    private forgotPasswordService: ForgotPasswordService,
    private router: Router,
    private route: ActivatedRoute,
    private styler: ApplicationStyler,
  ) {
    super();
  }

  ngOnInit() {
    this.model = '';
    this.isAlertVisible = false;
    this.isProccessing = false;
    // @TODO Refactor into Application Styling
    // this.businessStyleService.businessStyleSettings.subscribe({
    //   next: (settings) => {
    //     this.logoUrl = settings.businessLogoUrl;
    //     this.businessUrlKey = settings.businessUrlKey;
    //   }
    // })
  }

  public onSubmit() {
    this.isProccessing = true;
    this.forgotPasswordService.resetPassword(this.model, this.businessUrlKey).subscribe(
      () => this.onResetPasswordSuccess(),
      () => this.isProccessing = false,
      () => this.isProccessing = false
    );
  }

  public goBack() {
    this.router.navigate(['login'], { relativeTo: this.route.parent });
  }

  private onResetPasswordSuccess() {
    this.isAlertVisible = true;
  }
}
