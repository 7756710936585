import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../shared/components/base.component';
import {SetPasswordService} from './set-password.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApplicationConfig} from '../../config/application.config';
import {AuthorizationService} from '../shared/service/authorization.service';
import {TranslateService} from '@ngx-translate/core';
import {Localization} from '../shared/enums/localization';
import {ErrorType} from '../shared/enums/enums';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  providers: [SetPasswordService]
})
export class SetPasswordComponent extends BaseComponent implements OnInit {

  public culture: any;
  public translationCompleted: boolean;
  public password: string;
  public confirmPassword: string;
  public isProccessing: boolean;
  public isPasswordCreationDone: boolean;
  public isPasswordCreationError: boolean;
  public logoUrl: string;
  public confirmationCode: string;
  public firstName: string;
  public lastName: string;
  public username: string;
  public isLoading: boolean;
  public isConfirmationCodeCorrect: boolean;
  public confirmationCodeErrorMessage: string;
  public businessUrlKey: string;

  constructor(private setPasswordService: SetPasswordService, private router: Router, private route: ActivatedRoute,
              public applicationConfig: ApplicationConfig,
              private authorizationService: AuthorizationService,
              private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    this.translationCompleted = false;
    this.password = '';
    this.confirmPassword = '';
    this.isPasswordCreationDone = false;
    this.isPasswordCreationError = false;
    this.isProccessing = false;
    this.isLoading = true;
    this.isConfirmationCodeCorrect = true;
    this.route.params.subscribe(params => {
      this.authorizationService.clearSession();
      this.confirmationCode = params['confirmationCode'];
      this.culture = params['culture'];
      this.culture = this.culture == null ? 'en-US' : this.culture;
      this.translateService.onLangChange.subscribe(() => this.translationCompleted = true);
      this.translateService.use(this.culture);
      this.getUserByConfirmationCode();
    });
  }

  public onInputFocus() {
    this.isPasswordCreationError = false;
  }

  public areBothPasswordEqual(): boolean {
    return this.password === this.confirmPassword;
  }

  public doesPasswordHaveTargetedLenght(): boolean {
    return this.password.length >= this.applicationConfig.MIN_PASSWORD_LENGTH;
  }

  public goToLoginPage() {
    this.router.navigate(['login'], {relativeTo: this.route.parent});
  }

  public onSubmit() {
    this.isPasswordCreationError = false;
    this.isProccessing = true;
    this.setPasswordService.savePassword(this.confirmationCode, this.password).subscribe(
      () => this.isPasswordCreationDone = true,
      () => {
        this.isProccessing = false;
        this.isPasswordCreationError = true;
      },
      () => this.isProccessing = false
    );
  }

  public getUserFullName(): string {
    if (this.firstName && this.lastName){
      return this.firstName + ' ' + this.lastName;
    }
    else {
      //Fallback in case first or last name is not filled in.
      return this.username;
    }
  }

  private getUserByConfirmationCode() {
    this.setPasswordService.getUserByConfirmationCode(this.confirmationCode).subscribe(
      user => {
        this.isConfirmationCodeCorrect = true;
        this.isLoading = false;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.username = user.username;
        }, error => {
          this.confirmationCodeErrorMessage = this.getErrorMessage(error);
          this.isConfirmationCodeCorrect = false;
          this.isLoading = false;
        }
    );
  }

  private getErrorMessage(error: any) {
    switch (error.error.code) {
      case ErrorType.NOTEXIST:
        return this.translateService.instant(Localization.IncorrectConfirmationCode);
      case ErrorType.ALREADYEXISTS:
        return this.translateService.instant(Localization.AccountAlreadyConfirmed);
    }
    return this.translateService.instant(Localization.SomethingWentWrong);
  }
}
