import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/internal/operators';
import { UpdateModalComponent } from '../components/update-modal/update-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(appRef: ApplicationRef, private updates: SwUpdate, private modalService: MDBModalService) {
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everyHour$ = interval(60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everyHour$);
    everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
   }

  public checkForUpdates() {
    this.updates.available.subscribe(() => this.alertUser());
  }

  private alertUser() {
    this.modalService.show(UpdateModalComponent);
  }
}
