import {DashboardTile} from './model/dashboard-tile';
import {Localization} from '../shared/enums/localization';
import { Injectable, OnDestroy } from "@angular/core";
import { IssueNotificationService } from '../shared/service/issue-notification.service';
import { Subscription } from 'rxjs';

@Injectable()
export class DashboardService implements OnDestroy {

  private readonly Localization = Localization;
  private subscriptions: Subscription[] = [];

  private tiles = [
    new DashboardTile(this.Localization.Announcements,this.Localization.AnnouncementsDescription, 'announcements', 'Announcements'),
    new DashboardTile(this.Localization.IssueStatus,this.Localization.IssueStatusDescription, 'notifications', 'ProblemStatus'),
    new DashboardTile(this.Localization.Documents,this.Localization.DocumentsDescription, 'documents', 'Documents'),
  ];

  constructor(private issueNotificationService: IssueNotificationService) {
    // this.loadNotifications();
  }

  public getTiles(): DashboardTile[] {
    return this.tiles;
  }


  private loadNotifications(){
    this.subscriptions.push(this.issueNotificationService.getNotifications().subscribe((result) => {
      let filteredList = result.filter(x => x.hasUnreadMessage == true);
      if(filteredList.length > 0){
        this.tiles[1].unReadMessages = filteredList.length;
      }
    }));
  }

  public updateTileUnreadMessages(label: string, unReadMessages: number){
    const tileToBeUpdated = this.tiles.find(t => t.label === label);
    tileToBeUpdated.unReadMessages = unReadMessages;
  }

  

  public updateTile(icon: string, showEnvelope: boolean, url?: string) {
    const updatedTile =  this.tiles.find(e => e.icon === icon);
    updatedTile.showEnvelope = showEnvelope;

    if (url) {
      updatedTile.page = url;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
