import {Injectable} from '@angular/core';
import {concatMap, shareReplay, startWith} from 'rxjs/operators';
import {combineLatest, concat, Observable, of, Subject} from 'rxjs';
import {catchError, filter, switchMap, tap} from 'rxjs/internal/operators';
import {Accountservice} from './account-service';
import {ResidentLogin} from '../model/residentLogin.model';
import {BusinessIdentifier} from './business-identifier.service';
import {ApplicationStyleLoader} from './application-style-loader.service';
import {AuthorizationService} from './authorization.service';
import {ApplicationStyleSettings} from '../model/application-style-settings';

/**
 * Allows other parts of the application
 * to react to changes in the
 * Application Style.
 */
@Injectable({
  providedIn: 'root'
})
export class ApplicationStyleService {

  private load = new Subject();
  private load$ = this.load.asObservable().pipe();

  /**
   * The Application Style can be customised
   * by the business. When logged in it is
   * we'll determine the business via the
   * ResidentLogin otherwise we'll look
   * for a businessUrlKey parameter
   * @private
   */
  private styleIdentifiers$ = combineLatest([
    this.residentLogin$.pipe(startWith(null)),
    this.businessIdentifier.businessUrlKey$.pipe(startWith(null)),
    this.businessIdentifier.businessId$.pipe(startWith(null)),
  ]);


  public readonly settings$: Observable<ApplicationStyleSettings> = this.load$.pipe(
    startWith(null),
    switchMap(() => this.styleIdentifiers$),
    concatMap(identifiers => this.retrieveStyle(identifiers)),
    filter(hasApplicationStyle),
    startWith(new ApplicationStyleSettings()),
    shareReplay(1),
  );

  constructor(
    private settings: ApplicationStyleLoader,
    private account: Accountservice,
    private auth: AuthorizationService,
    private businessIdentifier: BusinessIdentifier,
  ) {
  }

  public refresh() {
    this.load.next(null);
    return this.settings$;
  }

  private retrieveStyle([resident, businessUrlKey, businessId]: [ResidentLogin, string, string]|[]): Observable<ApplicationStyleSettings|null> {
    if (!resident && !businessUrlKey && !businessId) {
      return of(new ApplicationStyleSettings());
    }
    return resident ? this.settings.forResident(resident) :
      (businessId ? this.settings.forBusiness(businessId) : this.settings.forBusinessUrlKey(businessUrlKey));
  }

  private get residentLogin$(): Observable<ResidentLogin|null> {
    return this.auth.token$.pipe(
      filter(Boolean),
      concatMap(() => this.account.getDetails())
    );
  }
}

function hasApplicationStyle(style: ApplicationStyleSettings | null) {
  return !!style;
}
