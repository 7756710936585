<div mdbModal #modal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-notify modal-danger" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <span [translate]="Localization.SetCultureConfirmationMessage"></span>
        </div>
        <div class="modal-footer justify-content-center">
          <chapps-success-button [label]="Localization.Yes" (clicked)="confirm()">
          </chapps-success-button>
          <chapps-danger-button [label]="Localization.No" (clicked)="modal.hide()">
          </chapps-danger-button>
        </div>
      </div>
    </div>
  </div>
