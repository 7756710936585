import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-symbol',
  templateUrl: './calendar-symbol.component.html',
  styleUrls: ['./calendar-symbol.component.scss']
})
export class CalendarSymbolComponent implements OnInit {

  @Input() calendarDate: Date;
  @Input() calendarColor: string;
  @Input() showBadge: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
