import { Injectable } from '@angular/core';
import {ApiRoutes} from '../../../config/api-routes';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Dwelling} from '../model/Dwelling';

@Injectable()
export class DwellingsService {

  constructor(
    private readonly api: ApiRoutes,
    private readonly http: HttpClient,
  ) { }

  public get mine(): Observable<Dwelling[]> {
    return this.http.get<Dwelling[]>(
      this.api.DWELLINGS
    );
  }
}
