<div>
  <ng-container *ngFor="let message of messages; index as i">
    <span *ngIf="showMessageDate(message.sentOn, i)" class="d-flex justify-content-center font-size-08">{{message.sentOn | customDate: applicationConfig.DATE_TIME_FORMAT}}</span>
    <ng-container *ngIf="!isThisLoggedUserMessage(message)">
      <app-recipient-message (photoClick)="onPhotoClick(message.photoUrl)"  [message]="message"></app-recipient-message>
    </ng-container>
    <ng-container *ngIf="isThisLoggedUserMessage(message)">
      <app-my-message (photoClick)="onPhotoClick(message.photoUrl)" *ngIf="isThisLoggedUserMessage(message)" [message]="message"></app-my-message>
    </ng-container>
  </ng-container>
  <div *ngIf="replyInProgress" class="mt-2 mb-3 pr-5 d-flex justify-content-end">
    <mdb-spinner spinnerType="small"></mdb-spinner>
  </div>
  <div *ngIf="!messages.length && !replyInProgress" class="alert alert-light mb-0" role="alert">
    {{Localization.EmptyChatMessage | translate}}
  </div>
</div>
