import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRoutes } from 'src/app/config/api-routes';

@Injectable()
export class ForgotPasswordService {
  constructor(private httpClient: HttpClient, private config: ApiRoutes) {}

  public resetPassword(email: string, businessUrlKey: string): Observable<any> {
    return this.httpClient.post(this.config.FORGOT_PASSWORD, { "username": email, "businessUrlKey": businessUrlKey });
  }
}
