import { Injectable } from "@angular/core";

@Injectable()
export class FlagService {
    constructor() {}

    public getFlagFromCulture(language: string): string {

        switch(language){
            case 'en':
                language = 'us';
                break;
            case 'nl-BE':
                language = 'be';
                break;
        }
        return 'assets/flags/' + language + '.png'
    }
}