import {PhotoViewerModel} from './model/photo-viewer-model';
import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';

@Injectable()
export class PhotoViewerService {
  show$: Subject<PhotoViewerModel> = new Subject<PhotoViewerModel>();

  public show(urls: string[], photoIndex: number) {
    this.show$.next(new PhotoViewerModel(urls, photoIndex));
  }
}
