import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BaseComponent} from '../../components/base.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LoginService} from 'src/app/modules/login/login.service';
import {ApplicationErrorCode} from '../../enums/application-error-code';
import {ResetPasswordService} from 'src/app/modules/reset-password/reset-password.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],
  providers: [LoginService, ResetPasswordService]
})
export class ResetPasswordDialogComponent extends BaseComponent implements OnInit, OnDestroy {

  public formGroup: FormGroup;
  hide: boolean = true;
  oldPasswordConfirmed: boolean = false;
  public businessUrlKey: string;

  private subscriptions: Subscription[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private resetPasswordService: ResetPasswordService,
  private dialogRef: MatDialogRef<ResetPasswordDialogComponent>) {
    super();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.initForm();
    //@TODO Refactor into Application Styling
    // this.businessStyleService.businessStyleSettings.subscribe({
    //   next: (settings) => {
    //     this.businessUrlKey = settings.businessUrlKey;
    //   }
    // })
  }

  private initForm() {
    this.formGroup = new FormGroup({
      password: new FormControl(null),
      newPassword: new FormControl(null),
    });
  }

  onSubmit(){
    if(this.oldPasswordConfirmed){
      this.resetPasswordService.savePassword(this.data.login.passwordResetCode, this.formGroup.controls.newPassword.value, this.data.businessUrlKey).subscribe((result) => {
        if(result){
          this.dialogRef.close(true);
        }
      },(error) => {
        this.dialogRef.close(false);
      },);
    }else {
      this.resetPasswordService.checkPassword(this.data.login.id, this.formGroup.controls.password.value).subscribe((response) =>
        this.onLogin(response),
      );
    }
  }

  private onLogin(response: string) {
    if(response == ApplicationErrorCode.OK){
      this.oldPasswordConfirmed = true;
    }else{
      this.formGroup.controls.password.setErrors({'incorrect': true});
    }
  }
}
