export class DashboardTile {
  constructor(
    public label: string,
    public description: string,
    public page: string, 
    public icon: string,
    public unReadMessages: number = 0,
    public disabled: boolean = false, 
    public showEnvelope: boolean = false) {
  }
}
