import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiRoutes} from '../../config/api-routes';
import {Observable} from 'rxjs';
import {LoginResponse} from '../shared/model/login-response';

@Injectable()
export class LoginService {
  constructor(private httpClient: HttpClient, private apiRoutes: ApiRoutes) {}

  public login(username: string, password: string): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(this.apiRoutes.LOGIN, { username, password });
  }
}
