export class ApplicationStyle {
  font?: string;
  pwaName?: string;
  pwaShortName?: string;
  pwaLogoFolder?: string;
  businessEmail?: string;
  businessName?: string;
  businessUrlKey?: string;
  businessLogoUrl = './assets/images/chappslogo.png';
  signInBackgroundUrl = 'assets/background.jpg';
  colors = new ApplicationStyleColors();
}

export class ApplicationStyleColors {
  baseColor1 = '#001659';
  baseColor2 = '#c0bed4';
  accent1 = '#6a86d9';
  accent2 = '#faa61a';
  progress1 = '#aabcf2';
  progress2 = '#f7f9fc';
  cardColor = '#f9f9fd';
  chevronColor = '#7b86aa';
  notificationColor = '#dc004e';
  menuText = '#445180';
}
