import {Component, OnInit, OnDestroy} from '@angular/core';
import {PhotoViewerService} from './photo-viewer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrls: ['./photo-viewer.component.scss'],
})
export class PhotoViewerComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  public photos: string[];
  public activePhoto: string;
  public activePhotoIndex: number;
  public visible: boolean;

  constructor(private service: PhotoViewerService) { }

  ngOnInit() {
    this.init();
    this.subscriptions.push(this.service.show$.subscribe(model  => {
      this.photos = model.urls;
      this.activePhotoIndex = model.photoIndex;
      this.activePhoto = this.photos[this.activePhotoIndex];
      this.visible = true;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  public hide() {
    this.visible = false;
  }

  public next() {
    this.activePhotoIndex++;
    this.activePhoto = this.photos[this.activePhotoIndex];
  }

  public prev() {
    this.activePhotoIndex--;
    this.activePhoto = this.photos[this.activePhotoIndex];
  }

  public isFirst(): boolean {
    return this.activePhotoIndex === 0;
  }

  public isLast(): boolean {
    return this.activePhotoIndex === this.photos.length - 1;
  }

  private init() {
    this.visible = false;
    this.photos = [];
    this.activePhotoIndex = 0;
    this.activePhoto = '';
  }
}
