import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {noop} from 'rxjs';
import {BaseComponent} from '../../../../shared/components/base.component';
import {IssueNotificationDetails} from '../../../../shared/model/issue-notification-details';
import {IssueNotificationService} from '../../../../shared/service/issue-notification.service';
import {ApplicationConfig} from '../../../../../config/application.config';
import {Location} from '@angular/common';

@Component({
  selector: 'app-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss'],
})
export class NotificationDetailsComponent extends BaseComponent implements OnInit {

  public issueNotificationId: number;
  public details: IssueNotificationDetails;
  public areIssueDetailsLoading: boolean;

  constructor(private issueNotificationService: IssueNotificationService, private route: ActivatedRoute,
              public applicationConfig: ApplicationConfig, private location: Location) {
    super();
  }

  ngOnInit() {
    this.details = {} as IssueNotificationDetails;
    this.route.params.subscribe(params => {
      this.issueNotificationId = parseInt(params['id'], 10);
      this.loadDetails();
    });
  }

  public onBackButtonClick() {
    this.location.back();
  }

  public loadDetails() {
    this.areIssueDetailsLoading = true;
    this.issueNotificationService.getIssueNotificationDetails(this.issueNotificationId).subscribe(
      response => this.details = response,
      noop,
      () => this.areIssueDetailsLoading = false
    );
  }

  public isDescriptionEmpty(): boolean {
    return this.details.description == null || this.details.description === '';
  }

  public isLocationEmpty(): boolean {
    return this.details.locationLabel == null || this.details.locationLabel === '';
  }
}
