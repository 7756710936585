import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiRoutes} from '../../config/api-routes';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/internal/operators';

@Injectable()
export class SetPasswordService {
  constructor(private httpClient: HttpClient, private apiRoutes: ApiRoutes) {}

  public savePassword(confirmationCode: string, password: string): Observable<any> {
   return this.httpClient.post(this.apiRoutes.SET_PASSWORD, {confirmationCode, password}).pipe(
      catchError(error => {
        return throwError(null);
      })
    );
  }

  public getUserByConfirmationCode(code: string): Observable<any> {
    return this.httpClient.get(this.apiRoutes.SET_PASSWORD_GET_USER_BY_CONFIRMATION_CODE + `?confirmationCode=${code}`).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }
}
