<form class="password-dialog" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-form-field class="dialog-item" *ngIf="!oldPasswordConfirmed">
      <mat-label>{{ Localization.EnterCurrentPassword | translate }}</mat-label>
      <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>
      <i  matSuffix (click)="hide = !hide" class="fas" [ngClass]="{'fa-eye': hide, 'fa-eye-slash': !hide}"></i>
    </mat-form-field>
    <small *ngIf="!formGroup.controls.password.valid && formGroup.controls.password.touched" class="form-text text-danger">{{Localization.PasswordInCorrect | translate}}<br/></small>
    <mat-form-field class="dialog-item" *ngIf="oldPasswordConfirmed">
      <mat-label>{{ Localization.EnterNewPassword | translate }}</mat-label>
      <input matInput formControlName="newPassword" [type]="hide ? 'password' : 'text'" required>
      <i  matSuffix (click)="hide = !hide" class="fas" [ngClass]="{'fa-eye': hide, 'fa-eye-slash': !hide}"></i>
    </mat-form-field>
    <mat-dialog-actions align="end" >
        <button mat-button mat-dialog-close>{{ Localization.Cancel | translate }}</button>
        <button mat-button>{{ Localization.Next | translate }}</button>
    </mat-dialog-actions>
</form>
