import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;
  public isDesktop$: BehaviorSubject<boolean>;

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.isDesktop$ = this.layoutService.isDesktop$;

  }

}
