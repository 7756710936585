import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-card-spinner',
  templateUrl: './card-spinner.component.html',
  styleUrls: ['./card-spinner.component.scss'],

})
export class CardSpinnerComponent {
  @Input() show: boolean;
  @Input() isFullScreen: boolean = false;
}
