import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ChappsImageCropperComponent} from '@npm.chapps.com/chapps.common.angular.library';
import {UploadingImage} from '@npm.chapps.com/chapps.common.angular.library/lib/components/others/image-cropper/model/uploading-image';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {LayoutService} from 'src/app/services/layout.service';
import {ResetPasswordService} from '../reset-password/reset-password.service';
import {BaseComponent} from '../shared/components/base.component';
import {ResetPasswordDialogComponent} from '../shared/dialogs/reset-password-dialog/reset-password-dialog.component';
import {SuccesDialogComponent} from '../shared/dialogs/succes-dialog/succes-dialog.component';
import {Building} from '../shared/model/building';
import {ResidentLogin} from '../shared/model/residentLogin.model';
import {Accountservice} from '../shared/service/account-service';
import {AuthorizationService} from '../shared/service/authorization.service';
import {BuildingService} from '../shared/service/building-service';
import {LocalStorageService} from '../shared/service/local-storage.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  providers: [ResetPasswordService]

})
export class MyAccountComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('image') image;
  @ViewChild(ChappsImageCropperComponent) chappsImageCropperComponent: ChappsImageCropperComponent;


  public logoUrl: string;
  public currentBuilding: Building
  public currentLogin$: Observable<ResidentLogin>;
  public images: Set<UploadingImage> = new Set();
  private subscriptions: Subscription[] = [];
  public isDesktop$: BehaviorSubject<boolean>;
  public businessUrlKey: string;

  constructor(
    private localStorageService: LocalStorageService,
    private buildingService: BuildingService,
    private accountService: Accountservice,
    public dialog: MatDialog,
    private layoutService: LayoutService,
    private resetPasswordService: ResetPasswordService,
    private authService: AuthorizationService,
    private router: Router) {
    super();
  }


  ngOnInit(): void {
    this.isDesktop$ = this.layoutService.isDesktop$;
    this.subscriptions.push(this.buildingService.getBuildings().subscribe((result: Building[]) => {
      this.currentBuilding = result[0];
    }));

    this.currentLogin$ = this.accountService.getDetails();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());  }

  public getUsername(): string {
    return `${this.localStorageService.getFirstname()} ${this.localStorageService.getLastname()}`;
  }

  public getUserEmail(): string {
    return this.localStorageService.getEmail();
  }

  public logout() {
    this.authService.clearSession();
    this.router.navigateByUrl('login');
  }

  public resetPassword(login: ResidentLogin){
    const dialogRef = this.dialog.open(ResetPasswordDialogComponent,{
      panelClass: 'custom-dialogbox-full',
      data: {login: login, businessUrlKey: this.businessUrlKey}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        this.dialog.open(SuccesDialogComponent,{
          autoFocus: false
        })
      }
    })
  }

  /*public uploadPhoto(){
    this.image.nativeElement.click();
  }*/

  /*fileChangeEvent(event: any): void {
    const files: { [key: string]: File } = this.image.nativeElement.files;
    const pendingFiles: File[] = [];

    for (const key in files) {
      if (!isNaN(parseInt(key, null))) {
        pendingFiles.push(files[key]);
      }
    }
    this.chappsImageCropperComponent.computeImages(pendingFiles);
  } */

  /*fileCropped(file: UploadingImage) {
    this.images.add(file);
  }*/

}
