import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-img-thumbnail',
  templateUrl: './img-thumbnail.component.html',
  styleUrls: ['./img-thumbnail.component.scss'],
})
export class ImgThumbnailComponent {

  @Input() url: string = null;
  @Input() size = 150;
  @Input() shadow = true;
  @Input() border = true;
  @Input() deleteButton: boolean = false;
  @Output() deleteClick: EventEmitter<any> = new EventEmitter<any>();


  constructor() {}

  public getContainerStyle(): any {
    return {'width.px': this.size, 'height.px': this.size};
  }

  public deleteClicked(event) {
    event.preventDefault();
    event.stopPropagation()
    this.deleteClick.emit();
  }

  public getOuterStyle(): any {
    const response = {'width.px': this.size, 'height.px': this.size};
    if (this.border) {
      response['border'] = '1px solid lightgrey';
    }
    return response;
  }
}
