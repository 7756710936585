<main>
  <div class="app-pre-loader" *ngIf="areTranslationsLoading">
    Loading<span class="d">.</span><span class="d d-2">.</span><span class="d d-3">.</span>
  </div>
  <div appFont >
    <app-loader *ngIf="areTranslationsLoading"></app-loader>
    <app-user-navbar *ngIf="!areTranslationsLoading && isUserLoggedIn() && !hideNavigation" [currentBuilding]="currentBuilding"></app-user-navbar>
    <router-outlet *ngIf="!areTranslationsLoading"></router-outlet>
  </div>
  <chapps-request-error-modal></chapps-request-error-modal>
  <app-photo-viewer></app-photo-viewer>
</main>


