import {BaseComponent} from '../../../../shared/components/base.component';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LocalStorageService} from '../../../../shared/service/local-storage.service';
import {IssueNotificationService} from '../../../../shared/service/issue-notification.service';
import {ActivatedRoute} from '@angular/router';
import {ApplicationConfig} from '../../../../../config/application.config';
import {IssueNotificationMessage} from '../../../../shared/model/issue-notification-message';
import {BehaviorSubject, noop, Subscription} from 'rxjs';
import {ChappsImageCropperComponent} from '@npm.chapps.com/chapps.common.angular.library';
import {UploadingImage} from '@npm.chapps.com/chapps.common.angular.library/lib/components/others/image-cropper/model/uploading-image';
import {LayoutService} from 'src/app/services/layout.service';

@Component({
  selector: 'app-notification-messages',
  templateUrl: './notification-messages.component.html',
  styleUrls: ['./notification-messages.component.scss'],
})
export class NotificationMessagesComponent extends BaseComponent implements OnInit {


  @Input() issueNotificationId: number;
  private subscriptions: Subscription[] = [];

  constructor(private issueNotificationService: IssueNotificationService, private route: ActivatedRoute,
              public applicationConfig: ApplicationConfig, private localStorageService: LocalStorageService, private layoutService: LayoutService) {
    super();
  }
  // public issueNotificationId: number;
  public messages: IssueNotificationMessage[];
  public areIssueMessagesLoading: boolean;
  public areFilesLoading: boolean;
  public isReplyInProgress: boolean;
  public reply: string;
  public files: Set<UploadingImage> = new Set();
  public isDesktop$: BehaviorSubject<boolean>;
  public alternateSendButtonColor: string;

  @ViewChild('file') file;
  @ViewChild('image') image;

  @ViewChild(ChappsImageCropperComponent) chappsImageCropperComponent: ChappsImageCropperComponent;

  ngOnInit() {
    this.isDesktop$ = this.layoutService.isDesktop$;
    // @TODO Refactor into Application Styling
    // this.subscriptions.push(this.businessStyleService.businessStyleSettings.subscribe((settings) => {
    //   this.alternateSendButtonColor = settings.titleTextColor;
    // }))
    this.areFilesLoading = false;

    this.reply = '';

    this.loadMessages();

  }

  public onKeyUp(event: any) {
    if (event.key === 'Enter' && this.reply !== '') {
      this.onSendButtonClick();
    }
  }

  fileChangeEvent(fromSource: string): void {

    const files: { [key: string]: File } = fromSource == 'file' ? this.file.nativeElement.files : this.image.nativeElement.files;
    const pendingFiles: File[] = [];

    for (const key in files) {
      if (!isNaN(parseInt(key, null))) {
        pendingFiles.push(files[key]);
      }
    }
    this.chappsImageCropperComponent.computeImages(pendingFiles);
}

  public addFiles() {
    this.file.nativeElement.click();
  }

  public deleteSelectedToUploadImage(image: UploadingImage) {
    this.files.delete(image);
  }

  public isSendButtonDisabled(): boolean {
    return this.isReplyInProgress || this.reply === '' && this.files.size === 0;
  }

  public onSendButtonClick() {
    if (this.isReplyInProgress) {
      return;
    }
    this.sendReply(this.reply, this.issueNotificationId);
  }

  public openCamera(){
    this.image.nativeElement.click();
  }

  private loadMessages() {
    this.areIssueMessagesLoading = true;
    this.issueNotificationService.getIssueNotificationMessages(this.issueNotificationId).subscribe(
      response => {
          this.messages = response;
          this.markMessagesAsRead();
      },
      noop,
      () => this.areIssueMessagesLoading = false
    );
  }

  private markMessagesAsRead() {
    const lastMessage = this.messages[this.messages.length - 1];
    const loggedUser = `${this.localStorageService.getFirstname()} ${this.localStorageService.getLastname()}`;
    if (lastMessage && !lastMessage.isRead && loggedUser !== lastMessage.sender) {
      this.issueNotificationService.markMessagesAsRead(this.issueNotificationId).subscribe();
    }
  }

  private sendReply(message: string, issueNotificationId: number) {
    this.isReplyInProgress = true;
    const files = Array.from(this.files).map((x: UploadingImage) => x.modifiedFile);
    this.issueNotificationService.sendIssueNotificationMessage(message, issueNotificationId, files).subscribe(
      response => {
        this.reply = '';
        this.files = new Set<UploadingImage>();
        this.loadMessages();
      },
      noop,
      () => this.isReplyInProgress = false
    );
  }

  fileCropped(file: UploadingImage) {
    this.files.add(file);
  }
}
