import {Injectable} from '@angular/core';
import {ApiRoutes} from '../../../config/api-routes';
import {Observable, of} from 'rxjs';
import { HttpClient, HttpParams} from '@angular/common/http';
import {Location} from '../model/location';
import {catchError, map} from 'rxjs/internal/operators';
import {Building} from '../model/building';
import {ApplicationConfig} from '../../../config/application.config';
import {ApplicationType} from '../enums/enums';
import { StructureSummary } from '../model/structureSummary.model';

@Injectable()
export class BuildingService {

  constructor(private httpClient: HttpClient, private apiRoutes: ApiRoutes, private applicationConfig: ApplicationConfig) {}

  public getBuildings(): Observable<Building[]> {
    return this.httpClient.get<Building[]>(this.apiRoutes.BUILDINGS).pipe(
      map(buildings => buildings.map(building => {
        if (building.thumbnailUrl == null) {
          building.thumbnailUrl = this.getBuildingThumbnailByApplicationType(building.application);
        }
        return building;
      })),
      catchError(error => {
        return of([]);
      })
    );
  }

  public getBuildingLocations(buildingId: number, application: string, parentNodeId: number): Observable<Location[]> {
    let params = new HttpParams()
    .set('buildingId', buildingId.toString())
    .set('application', application)
    .set('parentNodeId', parentNodeId.toString());
    return this.httpClient.get<Location[]>(this.apiRoutes.LOCATION_TEMPLATES, { params: params }).pipe(
      catchError(error => {
        return of([]);
      })
    );
  }

  private getBuildingThumbnailByApplicationType(type: string): string {
    switch (type as ApplicationType) {
      case ApplicationType.BUILDING:
        return this.applicationConfig.BUILDING_MANAGER_BUILDING_THUMBNAIL;
        break;
      case ApplicationType.ROOMS:
        return this.applicationConfig.DORM_BUILDING_THUMBNAIL;
        break;
      default:
        return this.applicationConfig.DEFAULT_BUILDING_THUMBNAIL;
        break;
    }
  }

  public getBuildingStructures(parentStructureId: number): Observable<StructureSummary[]>{
    return this.httpClient.get<StructureSummary[]>(this.apiRoutes.getRouteWithInjectedParams(this.apiRoutes.STRUCTURES, [parentStructureId.toString()]))
    .pipe(
      catchError(error => {
        return of([]);
      })
    )
  }
}
