import {BaseComponent} from '../../../base.component';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IssueNotificationMessage} from '../../../../model/issue-notification-message';

@Component({
  selector: 'app-recipient-message',
  templateUrl: './recipient-message.component.html',
  styleUrls: ['./recipient-message.component.scss'],
})
export class RecipientMessageComponent extends BaseComponent {

  @Input() message: IssueNotificationMessage;
  @Output() photoClick: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    super();
  }
}
