import {ModuleWithProviders, NgModule} from '@angular/core';
import {LocalStorageService} from './service/local-storage.service';
import {BaseComponent} from './components/base.component';
import {BuildingService} from './service/building-service';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MDBBootstrapModulesPro} from 'ng-uikit-pro-standard';
import {IssueNotificationService} from './service/issue-notification.service';
import {LoggerService} from './service/logger.service';
import {LocalizationService} from './service/localization.service';
import {AuthorizationService} from './service/authorization.service';
import {DocumentsService} from './service/documents.service';
import {
  DeleteConfirmationModalComponent
} from './components/delete-confirmation-modal/delete-confirmation-modal.component';
import {TitleComponent} from './components/title/title.component';
import {PhotoUploadService} from './service/photo-upload.service';
import {ErrorAlertComponent} from './components/error-alert/error-alert.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {TokenService} from './service/token.service';
import {RefreshTokenService} from './service/refresh-token.service';
import {ImgThumbnailComponent} from './components/img-thumbnail/img-thumbnail.component';
import {SmallIconThumbnailComponent} from './components/icon-thumbnail/icon-thumbnail.component';
import {
  DashboardNotificationTileComponent
} from './components/dashboard-notification-tile/dashboard-notification-tile.component';
import {DashboardBuildingTileComponent} from './components/dashboard-building-tile/dashboard-building-tile.component';
import {SmallImgThumbnailComponent} from './components/small-img-thumbnail/small-img-thumbnail.component';
import {ErrorLogService} from './service/error-log.service';
import {
  RecipientMessageComponent
} from './components/issue-notification-chat/components/recipient-message/recipient-message.component';
import {MyMessageComponent} from './components/issue-notification-chat/components/my-message/my-message.component';
import {CardSpinnerComponent} from './components/card-spinner/card-spinner.component';
import {Accountservice} from './service/account-service';
import {
  ChangeCultureConfirmationModalComponent
} from './components/change-culture-confirmation-modal/change-culture-confirmation-modal.component';
import {AnnouncementsService} from './service/announcements.service';
import {ChappsCommonAngularLibraryModule} from '@npm.chapps.com/chapps.common.angular.library';
import {NotificationListItemComponent} from './components/notification-list-item/notification-list-item.component';
import {HttpHeadersService} from './service/http-headers.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from '../../http/auth.interceptor';
import {ProgressIndicatorComponent} from './components/progress-indicator/progress-indicator.component';
import {StepComponent} from './components/step/step.component';
import {ProgressionStepComponent} from '../new-notification/steps/progression-step/progression-step.component';
import {EmptyListComponent} from './components/empty-list/empty-list.component';
import {ResetPasswordDialogComponent} from './dialogs/reset-password-dialog/reset-password-dialog.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {CalendarSymbolComponent} from './components/calendar-symbol/calendar-symbol.component';
import {PageHeaderComponent} from './components/page-header/page-header.component';
import {SuccesDialogComponent} from './dialogs/succes-dialog/succes-dialog.component';
import {PipesModule} from 'src/app/pipes/pipes.module';
import { StructureService } from './service/structure-service';
import {UserNavbarComponent} from './components/user-navbar/user-navbar.component';
import {IssueNotificationChatComponent} from './components/issue-notification-chat/issue-notification-chat.component';
import {DwellingsService} from './service/dwellings.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    MDBBootstrapModulesPro,
    AngularSvgIconModule,
    ChappsCommonAngularLibraryModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    PipesModule,
  ],
  declarations: [
    BaseComponent,
    DeleteConfirmationModalComponent,
    TitleComponent,
    ErrorAlertComponent,
    ImgThumbnailComponent,
    SmallImgThumbnailComponent,
    SmallIconThumbnailComponent,
    DashboardNotificationTileComponent,
    DashboardBuildingTileComponent,
    RecipientMessageComponent,
    MyMessageComponent,
    CardSpinnerComponent,
    ChangeCultureConfirmationModalComponent,
    NotificationListItemComponent,
    ProgressIndicatorComponent,
    StepComponent,
    ProgressionStepComponent,
    EmptyListComponent,
    ResetPasswordDialogComponent,
    CalendarSymbolComponent,
    PageHeaderComponent,
    SuccesDialogComponent,
    UserNavbarComponent,
    IssueNotificationChatComponent,
  ],
  exports: [
    BaseComponent,
    DeleteConfirmationModalComponent,
    TitleComponent,
    ErrorAlertComponent,
    ImgThumbnailComponent,
    SmallImgThumbnailComponent,
    SmallIconThumbnailComponent,
    DashboardNotificationTileComponent,
    DashboardBuildingTileComponent,
    RecipientMessageComponent,
    MyMessageComponent,
    CardSpinnerComponent,
    ChangeCultureConfirmationModalComponent,
    ProgressIndicatorComponent,
    StepComponent,
    ProgressionStepComponent,
    EmptyListComponent,
    CalendarSymbolComponent,
    PageHeaderComponent,
    UserNavbarComponent,
    IssueNotificationChatComponent,
  ],
  entryComponents: [NotificationListItemComponent]
})
export class SharedModule {

  static forChild(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      ]
    };
  }

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        LocalizationService,
        AuthorizationService,
        LoggerService,
        LocalStorageService,
        BuildingService,
        StructureService,
        DwellingsService,
        IssueNotificationService,
        DocumentsService,
        PhotoUploadService,
        TokenService,
        RefreshTokenService,
        ErrorLogService,
        Accountservice,
        AnnouncementsService,
        HttpHeadersService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      ]
    };
  }
}
